import * as React from "react"

import slugify from "@sindresorhus/slugify"
import { actionStyles } from "./action.styles"
import { createLink } from "../../lib/create-link"
import { Icon } from "../elements/icon"
import { Link as GatsbyLink } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { useGtag } from "../../lib/use-gtag"
import type { ActionStylesProps } from "./action.styles"
import type { ContentfulAction } from "../contentful/contentful-action"

export type ActionProps = {
  model: ContentfulAction
  variant: ActionStylesProps["variant"]
  options?: ActionStylesProps["options"]
}

export const Action: React.FC<ActionProps> = ({ model, variant, options }) => {
  const { anchor, description, eventId, heading, icon, url, page, query } =
    model

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    useGtag("event", "click", {
      event_id: event.currentTarget.dataset.analyticsId,
      event_category: "engagement",
      event_label: event.currentTarget.getAttribute("href") ?? undefined,
    })
  }

  const to = createLink({ page, url, query, anchor })
  const analyticsId = `action:${
    slugify(eventId || "") || slugify(heading || "")
  }`
  const styles = actionStyles({ variant, options })

  const forwardProps = {
    "aria-label": description?.trim(),
    "data-analytics-id": analyticsId,
    css: styles,
  }

  const internal = /^\/(?!\/)/.test(to)
  if (internal) {
    return (
      <GatsbyLink
        data-track-content=""
        data-content-name="Internal link"
        data-content-piece={heading?.trim()}
        data-content-target={to}
        to={to}
        onClick={handleOnClick}
        {...forwardProps}
      >
        {heading}
        {icon && <Icon variant="small" icon={icon} />}
      </GatsbyLink>
    )
  }

  if (!internal && variant === "outbound") {
    return (
      <OutboundLink
        href={to}
        data-track-content=""
        data-content-name="Advertisement"
        data-content-piece={heading?.trim()}
        data-content-target={to}
        {...forwardProps}
        rel="noreferrer noopener"
        target="_blank"
      >
        {heading}
        {icon && <Icon variant="small" icon={icon} />}
      </OutboundLink>
    )
  }

  return (
    <a
      data-track-content=""
      data-content-name="External link"
      data-content-piece={heading?.trim()}
      data-content-target={to}
      href={to}
      onClick={handleOnClick}
      {...forwardProps}
      rel="noreferrer noopener"
      target="_blank"
    >
      {heading}
      {icon && <Icon variant="small" icon={icon} />}
    </a>
  )
}
