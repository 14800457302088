import { theme } from "../../styles/global-css-variables.css"
import { mediaQuery } from "../../styles/media-query"
import { ExerciseSectionVariant } from "../contentful/contentful-exercise-section"
import type { CSSObject } from "@emotion/react"
import type { Variants, Options, StylesProps } from "../../styles/types"

type VariantStyle = ExerciseSectionVariant
type OptionStyle = Options<"">

const base: CSSObject = {
  color: theme.colors.focus,
}

const variants: Variants<VariantStyle> = {
  block: {},
}

export type ExerciseIconStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const exerciseIconStyles: StylesProps<ExerciseIconStylesProps> = ({
  variant,
}) => {
  return mediaQuery([base, variants[variant]])
}
