type CreateLinkProps = {
  (params: {
    page: {
      slug: string
    }
    url: string
    query: string
    anchor: string
  }): string
}

export const createLink: CreateLinkProps = ({ page, url, query, anchor }) => {
  const path = page?.slug || url
  const queryString = query && `?` + query
  const anchorHash = anchor && `#` + anchor

  return (
    path + (queryString ? queryString : "") + (anchorHash ? anchorHash : "")
  )
}
