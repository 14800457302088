import * as React from "react"

import { advertisementMediaStyles } from "../models/advertisement-media.styles"
import { getSingleAsset } from "./get-single-asset"
import type { AssetStylesProps } from "../models/asset.styles"
import type { AdvertisementSectionVariant } from "../contentful/contentful-advertisement-section"
import type { ContentfulAsset } from "../contentful/contentful-asset"

export type AdvertisementSingleMediaProps = {
  model: ContentfulAsset
  variant: AdvertisementSectionVariant
  poster: ContentfulAsset
}

export const AdvertisementSingleMedia: React.FC<
  AdvertisementSingleMediaProps
> = ({ model, variant, poster }) => {
  const variants: Record<
    AdvertisementSectionVariant,
    AssetStylesProps["variant"]
  > = {
    block: "medium",
    featured: "medium",
    "full-width": "large",
  }

  const options = {
    square: undefined,
    wide: ["block"].includes(variant),
    display: ["featured"].includes(variant),
  }

  const styles = advertisementMediaStyles({ variant })

  return (
    <div css={styles}>
      {getSingleAsset({
        asset: model,
        poster,
        variant: variants[variant],
        options,
      })}
    </div>
  )
}
