import * as React from "react"

import slugify from "@sindresorhus/slugify"
import type { ContentfulExercise } from "../contentful/contentful-exercise"
import type {
  ExerciseSectionOptions,
  ExerciseSectionVariant,
} from "../contentful/contentful-exercise-section"
import { exerciseStyles } from "./exercise.styles"
import { useGtag } from "../../lib/use-gtag"
import { ExerciseContent } from "./exercise-content"
import { ExerciseSingleMedia } from "../deprecated/exercise-single-media"
import MuxVideoPlayer from "../elements/mux-video-player"

export type ExerciseProps = {
  index: number
  model: ContentfulExercise
  options: ExerciseSectionOptions
  variant: ExerciseSectionVariant
}

export const Exercise: React.FC<ExerciseProps> = ({
  index,
  model,
  options,
  variant,
}) => {
  const [triggered, setTriggered] = React.useState(false)
  const { heading, singleMedia } = model

  const handleOnMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (triggered) return null
    setTriggered(!triggered)

    useGtag("event", "hover", {
      event_id: event.currentTarget.dataset.analyticsId,
      event_category: "engagement",
      non_interaction: true,
    })
  }

  const analyticsId = `exercise:${slugify(heading || "")}`
  const styles = exerciseStyles({ variant })

  const Media: React.FC<{ model: ContentfulExercise; variant: "block" }> = ({
    model,
    variant,
  }) => {
    const { mux, heading, singleMedia, poster } = model

    if (mux && mux.ready) {
      return (
        <MuxVideoPlayer
          playbackId={mux.playbackId ?? ""}
          videoId={mux.id}
          videoTitle={heading ?? ""}
        />
      )
    }
    return (
      <ExerciseSingleMedia
        variant={variant}
        model={singleMedia}
        poster={poster}
      />
    )
  }

  return (
    <div
      css={styles}
      data-analytics-id={analyticsId}
      onMouseEnter={handleOnMouseEnter}
    >
      {options.media && singleMedia && (
        <Media model={model} variant={variant} />
      )}
      <ExerciseContent
        index={index}
        model={model}
        options={options}
        variant={variant}
      />
    </div>
  )
}
