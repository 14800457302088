import type { CSSObject } from "@emotion/react"
import { theme } from "../../styles/global-css-variables.css"
import { mediaQuery } from "../../styles/media-query"
import { StylesProps, Variants } from "../../styles/types"
import { SearchSectionVariant } from "../contentful/contentful-search-section"

type VariantStyle = SearchSectionVariant

const base: CSSObject = {
  position: "relative",
  flexDirection: "column",
  maxWidth: 768,
  padding: theme.spacing.large,
  margin: "auto",
  width: "100%",
}
const variants: Variants<VariantStyle> = {
  extended: {},
  compact: {
    maxWidth: 420,
  },
}

export type SearchSectionStylesProps = {
  variant: VariantStyle
}

export const searchSectionStyles: StylesProps<SearchSectionStylesProps> = ({
  variant,
}) => {
  return mediaQuery([
    base,
    variants[variant],
    !(variant === "compact") && {
      minHeight: "calc(100vh - 241px)",
    },
  ])
}

export const seachInputStyles: CSSObject = {
  fontFamily: theme.font.family.body,
  fontSize: theme.font.size.default,
  width: "100%",
  lineHeight: 1,
  padding: 8,
  border: "1px solid transparent",
  borderColor: "silver",
  borderRadius: 4,
  "::placeholder": {
    color: "#888",
  },
  ":focus": {
    outline: "2px solid transparent",
    outlineColor: theme.colors.focus,
    borderColor: theme.colors.link,
  },
  ":focus-visible": {
    outlineOffset: 0,
    outlineColor: "#F75F13",
  },
  "::-webkit-search-cancel-button": {
    WebkitAppearance: "none",
    height: "1em",
    width: "1em",
    borderRadius: "50em",
    background:
      "url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%",
    backgroundSize: "contain",
    opacity: 0,
    pointerEvents: "none",
    cursor: "pointer",
  },
  ":focus::-webkit-search-cancel-button": {
    opacity: 0.3,
    pointerEvents: "all",
  },
}

export const searchTermStyles: CSSObject = {
  fontFamily: theme.font.family.body,
  fontSize: theme.font.size.xxlarge,
  margin: "3rem 0 2rem",
  wordBreak: "break-word",
  ":first-letter": {
    textTransform: "capitalize",
  },
}

export const searchLabelStyles: CSSObject = {
  boxSizing: "border-box",
  display: "flex",
  fontFamily: theme.font.family.body,
  fontSize: theme.font.size.small,
  color: "#999",
  paddingTop: theme.spacing.small,
  paddingBottom: theme.spacing.small,
}
