import * as React from "react"

import SemanticElements from "../layout/semantic-elements"
import { AdvertisementSection } from "./advertisement-section"
import { CalendlySection } from "./calendly-section"
import { createUuid } from "../../lib/create-uuid"
import { ExerciseSection } from "./exercise-section"
import { NavigationSection } from "./navigation-section"
import { SearchSection } from "./search-section"
import { TextSection } from "./text-section"
import { TopicSection } from "./topic-section"
import type { ContentfulAdvertisementSection } from "../contentful/contentful-advertisement-section"
import type { ContentfulCalendlySection } from "../contentful/contentful-calendly-section"
import type { ContentfulExerciseSection } from "../contentful/contentful-exercise-section"
import type { ContentfulNavigationSection } from "../contentful/contentful-navigation-section"
import type { ContentfulSearchSection } from "../contentful/contentful-search-section"
import type { ContentfulTextSection } from "../contentful/contentful-text-section"
import type { ContentfulTopicSection } from "../contentful/contentful-topic-section"
import type { ContentfulGlobals } from "../contentful/contentful-globals"

type DataRecord<Data extends string> = Partial<Record<Data, number>>
export type DataProps = DataRecord<"sectionIndex" | "topicIndex">

type ContentfulSection =
  | ContentfulAdvertisementSection
  | ContentfulCalendlySection
  | ContentfulExerciseSection
  | ContentfulNavigationSection
  | ContentfulSearchSection
  | ContentfulTextSection
  | ContentfulTopicSection

type SectionProps = {
  model: ContentfulSection[]
  globals: ContentfulGlobals
}

export const Section: React.FC<SectionProps> = ({ model, globals }) => {
  const { skipToContent } = globals

  return (
    <SemanticElements skipToContent={skipToContent}>
      {model.map((section: ContentfulSection, index) => {
        const { id } = section

        return (
          <React.Fragment key={createUuid(id)}>
            {getSection({ section, data: { sectionIndex: index }, globals })}
          </React.Fragment>
        )
      })}
    </SemanticElements>
  )
}

type GetSectionProps = {
  section: ContentfulSection
  data: DataProps
  globals: ContentfulGlobals
}

const getSection = ({ section, data, globals }: GetSectionProps) => {
  switch (section.__typename) {
    case `ContentfulAdvertisementSection`:
      return (
        <AdvertisementSection
          model={section as ContentfulAdvertisementSection}
        />
      )

    case `ContentfulNavigationSection`:
      return (
        <NavigationSection
          model={section as ContentfulNavigationSection}
          globals={globals}
        />
      )

    case `ContentfulTopicSection`:
      return (
        <TopicSection model={section as ContentfulTopicSection} data={data} />
      )

    case `ContentfulExerciseSection`:
      return <ExerciseSection model={section as ContentfulExerciseSection} />

    case `ContentfulTextSection`:
      return <TextSection model={section as ContentfulTextSection} />

    case `ContentfulSearchSection`:
      return <SearchSection model={section as ContentfulSearchSection} />

    case `ContentfulCalendlySection`:
      return <CalendlySection model={section as ContentfulCalendlySection} />

    default:
      return null
  }
}
