import * as React from "react"
import { iframeStyles } from "./calendly-section.styles"
import { Analytics } from "../layout/analytics"
import type { ContentfulCalendlySection } from "../contentful/contentful-calendly-section"

type CalendlySectionProps = {
  model: ContentfulCalendlySection
}

export const CalendlySection: React.FC<CalendlySectionProps> = ({ model }) => {
  const { url, eventId } = model

  return (
    <Analytics area="section" eventId={eventId} variant="calendly">
      <iframe
        css={iframeStyles}
        className="calendly-inline-widget"
        src={url + `?hide_gdpr_banner=1&primary_color=f75f13`}
        frameBorder={0}
      />
    </Analytics>
  )
}
