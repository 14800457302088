export type DeprecatedTopicOptions =
  | "Hide abstract"
  | "Hide action"
  | "Hide heading"
  | "Hide icon"
  | "Hide media"
  | "Reversed order"
  | undefined

type TopicOptionsKeys =
  | "abstract"
  | "action"
  | "heading"
  | "icon"
  | "media"
  | "reversed"

type TopicOptionsList = Record<TopicOptionsKeys, DeprecatedTopicOptions>

const topicOptionsList: TopicOptionsList = {
  abstract: "Hide abstract",
  action: "Hide action",
  heading: "Hide heading",
  icon: "Hide icon",
  media: "Hide media",
  reversed: "Reversed order",
}

type TopicOptions = Partial<Record<TopicOptionsKeys, boolean>>

export const useTopicOptions = (
  array: DeprecatedTopicOptions[]
): TopicOptions => {
  const topicOptions = array
  let options: TopicOptions = {}

  if (topicOptions === null) {
    options = {
      abstract: true,
      action: true,
      heading: true,
      icon: true,
      media: true,
      reversed: true,
    }

    return options
  }

  Object.keys(topicOptionsList).forEach(key => {
    const getOption = topicOptions.includes(
      topicOptionsList[key as TopicOptionsKeys]
    )
    options[key as TopicOptionsKeys] = !getOption
  })

  return options
}
