import type { CSSObject } from "@emotion/react"
import { theme } from "../../styles/global-css-variables.css"
import { mediaQuery } from "../../styles/media-query"
import type { Variants, Options, StylesProps } from "../../styles/types"
import { AdvertisementSectionVariant } from "../contentful/contentful-advertisement-section"

type VariantStyle = AdvertisementSectionVariant
type OptionStyle = Options<"">

const base: CSSObject = {
  color: theme.colors.focus,
}

const variants: Variants<VariantStyle> = {
  block: {},
  featured: {},
  "full-width": {},
}

export type AdvertisementIconStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const advertisementIconStyles: StylesProps<
  AdvertisementIconStylesProps
> = ({ variant }) => {
  return mediaQuery([base, variants[variant]])
}
