import type { ExerciseSectionVariant } from "../contentful/contentful-exercise-section"
import { Icon } from "../elements/icon"
import type { IconStylesProps } from "../elements/icon.styles"
import { exerciseIconStyles } from "./exercise-icon.styles"

export type ExerciseIconProps = {
  model: string
  variant: ExerciseSectionVariant
}

export const ExerciseIcon: React.FC<ExerciseIconProps> = ({
  model,
  variant,
}) => {
  const variants: Record<ExerciseSectionVariant, IconStylesProps["variant"]> = {
    block: "medium",
  }

  const styles = exerciseIconStyles({ variant })

  return (
    <div css={styles}>
      <Icon variant={variants[variant]} icon={model} />
    </div>
  )
}
