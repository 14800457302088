import type { AdvertisementSectionVariant } from "../contentful/contentful-advertisement-section"
import { Group } from "../layout/group"
import { ContentfulAction } from "../contentful/contentful-action"
import { createUuid } from "../../lib/create-uuid"
import { Action } from "../elements/action"
import { GroupStylesProps } from "../layout/group.styles"
import { advertisementActionsStyles } from "./advertisement-actions.styles"
import { ActionStylesProps } from "../elements/action.styles"

export type AdvertisementProps = {
  model: ContentfulAction[]
  variant: AdvertisementSectionVariant
}

export const AdvertisementActions: React.FC<AdvertisementProps> = ({
  model,
  variant,
}) => {
  const groupOptions: GroupStylesProps["options"] = {
    reverse: undefined,
    center: true,
    space: undefined,
  }

  const actionOptions: ActionStylesProps["options"] = {
    expand: true,
  }

  const styles = advertisementActionsStyles({ variant })

  return (
    <Group css={styles} options={groupOptions} variant="row">
      {model.slice(0, 1).map((action: ContentfulAction) => {
        const { id } = action

        return (
          <Action
            key={createUuid(id)}
            model={action}
            options={actionOptions}
            variant="outbound"
          />
        )
      })}
    </Group>
  )
}
