import * as React from "react"
import { Link } from "../elements/link"
import { searchResultsStyles } from "./search-results.styles"
import type { SearchSectionVariant } from "../contentful/contentful-search-section"
import { theme } from "../../styles/global-css-variables.css"

type NodeQuery = {
  readonly node: Queries.ContentfulPage
}

export type SearchResultsProps = {
  location: Window["location"]
  pages: ReadonlyArray<Queries.ContentfulPageEdge>
  query: string
  variant: SearchSectionVariant
  locale: string
}

export const SearchResults: React.FC<SearchResultsProps> = ({
  variant,
  location,
  pages,
  query,
  locale,
}) => {
  const [origin, setOrigin] = React.useState("")

  React.useEffect(() => {
    setOrigin(location.origin)
  }, [])

  const compactView = ["compact"].includes(variant)
  const sliceArray = compactView ? 5 : pages.length
  const toSearchPageWithQuery =
    locale === "nl-NL" ? "/zoeken?q=" + query : "/search?q=" + query

  return (
    <ul css={searchResultsStyles({ variant })}>
      {pages?.slice(0, sliceArray).map(({ node }: NodeQuery) => {
        return (
          <li key={node.id}>
            <h3>{node.seoTitle}</h3>
            <p>{node.seoDescription}</p>
            <Link to={node.slug ?? ""} aria-current="false">
              {origin}
              {node.slug}
            </Link>
          </li>
        )
      })}
      {compactView && pages.length > 4 && (
        <Link
          to={toSearchPageWithQuery}
          replace={true}
          css={{
            backgroundColor: theme.colors.light,
            color: "#666!important",
            display: "block",
            fontFamily: theme.font.family.body,
            fontSize: theme.font.size.small,
            fontWeight: theme.font.weight.bold,
            padding: theme.spacing.small,
            position: "relative",
            textAlign: "center",
            ":hover": {
              backgroundColor: "#f9f9f9",
            },
          }}
        >
          {locale === "nl-NL" ? "Bekijk meer resultaten" : "More results"}
        </Link>
      )}
    </ul>
  )
}
