import { theme } from "../../styles/global-css-variables.css"
import { mediaQuery } from "../../styles/media-query"
import type { CSSObject } from "@emotion/react"
import type { Variants, Options, StylesProps } from "../../styles/types"
import type { AdvertisementSectionVariant } from "../contentful/contentful-advertisement-section"

type VariantStyle = AdvertisementSectionVariant
type OptionStyle = Options<"reversed">

const base: CSSObject = {
  backgroundColor: theme.colors.section,
  border: "1px solid white",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  boxShadow: `0px 0px 16px ${theme.colors.border}`,
  transition: "box-shadow .3s cubic-bezier(0,0,.5,1)",
  ":hover, :focus": {
    boxShadow: "2px 4px 16px rgba(0,0,0,.16)",
  },
}

const variants: Variants<VariantStyle> = {
  block: {
    backgroundColor: theme.colors.section,
    borderRadius: 6,
    overflow: "hidden",
  },
  featured: {
    alignItems: "center",
    display: "flex",
    flexDirection: ["column", "row"],
    justifyContent: "space-evenly",
    paddingBottom: theme.spacing.default,
    paddingTop: theme.spacing.default,
    "> div": {
      width: ["100%", "50%"],
    },
    ":nth-of-type(even) > div:nth-of-type(2n)": {
      order: -1,
    },
  },
  "full-width": {
    borderRadius: 6,
    overflow: "hidden",
  },
}

const reversed: Variants<VariantStyle> = {
  block: {
    flexDirection: "column-reverse",
  },
  featured: {
    flexDirection: ["column-reverse", "row-reverse"],
  },
  "full-width": {
    flexDirection: "column-reverse",
  },
}

export type AdvertisementStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const advertisementStyles: StylesProps<AdvertisementStylesProps> = ({
  variant,
  options,
}: AdvertisementStylesProps) => {
  return mediaQuery([
    base,
    variants[variant],
    options?.reversed && reversed[variant],
  ])
}
