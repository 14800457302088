import * as React from "react"

import type { CSSObject } from "@emotion/react"
import { Link } from "../elements/link"
import { theme } from "../../styles/global-css-variables.css"

// Check if window or document is defined
const isBrowser = () => {
  return typeof window !== "undefined" || typeof document !== "undefined"
}

const gaPropertyId = "ga-disable-G-QC22JHH523"

// Global Window types
declare global {
  interface Window {
    "ga-disable-G-QC22JHH523"?: boolean
  }
}

// User Consent localStorage values types
type UserConsent = {
  value: boolean
  expiry?: Date
}

// Check if cookie exist
if (isBrowser() && document.cookie.indexOf(gaPropertyId + "=true") > -1) {
  window[gaPropertyId] = true
}

// Create expiry date
const setExpiryDate = (ttl: number) => {
  const date = new Date()
  date.setDate(date.getDate() + ttl ?? null)
  return date
}

// Set GA disable cookie
// const gaOptout = () => {
// const options: Intl.DateTimeFormatOptions = {
//   weekday: 'short',
//   year: 'numeric',
//   month: 'short',
//   day: 'numeric',
// }
// Date String options
//console.log(setExpiryDate(1).toLocaleDateString(undefined, options))

//   document.cookie =
//     gaPropertyId + "=true; expires=" + setExpiryDate(1) + "; path=/"
//   window[gaPropertyId] = true
// }

// Get current localStorage value based on key
const getValue = (key: string, defaultObject: UserConsent) => {
  return isBrowser() && window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key) ?? "")
    : defaultObject
}

// Set localStorage value
const setValue = (key: string, object: UserConsent) => {
  window.localStorage.setItem(key, JSON.stringify(object))
}

// Use current state or change state based on user consent
const useConsentState = (key: string, defaultObject: UserConsent) => {
  const [object, setObject] = React.useState(() => {
    return getValue(key, defaultObject)
  })

  React.useEffect(() => {
    setValue(key, object)
  }, [key, object])

  return [object, setObject]
}

const getExpiryDate = (key: string) => {
  const getItem = localStorage.getItem(key)
  if (!getItem) {
    return null
  }
  const object = JSON.parse(getItem)
  const current = new Date()
  // compare the expiry time of the item with the current time
  if (current.getTime() > object.expiry) {
    localStorage.removeItem(key)
  }
  return null
}

// Styling
const containerStyle: CSSObject = {
  zIndex: 1001,
  position: "fixed",
  bottom: 8,
  left: 8,
  right: 8,
  width: "calc(100% - 16px)",
}

const contentStyle: CSSObject = {
  padding: theme.spacing.small,
  maxWidth: 960,
  margin: "auto",
  display: "flex",
  justifyContent: "space-between",
  boxShadow: "rgb(0 0 0 / 15%) 0px 2px 6px 0px",
  borderRadius: 4,
  border: "1px solid transparent",
  borderColor: theme.colors.border,
  alignItems: "center",
  backgroundColor: theme.colors.light,
  "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
    backdropFilter: ["none", "none", "saturate(180%) blur(20px)"],
    background: [
      theme.colors.light,
      theme.colors.light,
      "rgba(255, 255, 255, 0.8)",
    ],
  },
}

const paragraphStyle: CSSObject = {
  fontFamily: theme.font.family.body,
  fontSize: theme.font.size.medium,
  color: theme.colors.text,
  padding: theme.spacing.medium,
  margin: 0,
  a: {
    color: theme.colors.link,
    ":hover": {
      textDecoration: "underline",
    },
  },
}

const buttonStyle: CSSObject = {
  color: theme.colors.link,
  background: "transparent",
  border: "none",
  cursor: "pointer",
  display: "flex",
  padding: theme.spacing.small,
}

type CookieConsentProps = {
  model: {
    banner: string
    button: string
  }
}

export const CookieConsent: React.FC<CookieConsentProps> = ({ model }) => {
  const { banner, button } = model

  // Check if local storage is expired
  if (isBrowser()) {
    getExpiryDate("userConsent")
  }

  const [userConsent, setUserConsent] = useConsentState("userConsent", {
    value: false,
    expiry: undefined,
  })

  if (!userConsent.value) {
    return (
      <div css={containerStyle}>
        <div
          css={contentStyle}
          role="dialog"
          aria-labelledby="consentTitle"
          aria-describedby="consentDescription"
        >
          <h3 id="consentTitle" hidden>
            Cookies
          </h3>
          <p css={paragraphStyle} id="consentDescription">
            {banner} <Link to="/privacy-policy">{button}</Link>
          </p>
          <button
            aria-label="Close"
            css={buttonStyle}
            onClick={() =>
              setUserConsent({
                value: true,
                expiry: setExpiryDate(30).getTime(),
              })
            }
          >
            <span hidden>Close</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              aria-hidden="true"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
      </div>
    )
  }

  return null
}
