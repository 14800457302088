import type { ContentfulNavigation } from "../contentful/contentful-navigation"
import type {
  ContentfulNavigationSection,
  NavigationSectionOptions,
} from "../contentful/contentful-navigation-section"
import { Navigation } from "./navigation"
import { createUuid } from "../../lib/create-uuid"
import { Analytics } from "../layout/analytics"
import { Link } from "../elements/link"
import { GridTemplate } from "../layout/grid-template"
import type { UseTypesOf } from "../../lib/use-types-of"
import { focusStyles } from "./text-section.styles"
import { useGtag } from "../../lib/use-gtag"

type ContainerProps = UseTypesOf["div"] & {
  options: NavigationSectionOptions
}

const Container = ({ children, options }: ContainerProps) => {
  if (options.branding) {
    return <GridTemplate variant="branding">{children}</GridTemplate>
  }

  return (
    <div
      css={{
        display: "flex",
        maxWidth: 1380,
        paddingTop: 24,
        paddingBottom: 24,
        margin: "auto",
      }}
    >
      {children}
    </div>
  )
}

export type NavigationSitemapProps = UseTypesOf["div"] & {
  model: ContentfulNavigationSection
}

export const NavigationSitemap: React.FC<NavigationSitemapProps> = ({
  model,
}) => {
  const { branding, heading, eventId, navigations, variant, logo } = model

  const options = {
    branding,
    heading,
  }

  const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    useGtag("event", "click", {
      event_id: event.currentTarget.dataset.analyticsId,
      event_category: "engagement",
      event_label: event.currentTarget.getAttribute("href") ?? undefined,
    })
  }

  return (
    <Analytics area="nav" eventId={eventId} variant="sitemap">
      <Container options={options}>
        {options.branding && logo && (
          <Link
            aria-label={logo.title}
            onClick={handleOnClick}
            data-analytics-id="action:logo-sitemap"
            css={{
              gridArea: "branding",
              display: "flex",
              width: 200,
              height: 40,
              marginBottom: "auto",
              ":focus": focusStyles,
            }}
            to="/"
          >
            <img
              alt={logo.description}
              src={`${logo.url}?w=400&fm=webp`}
              title={logo.title}
              width={200}
              height={40}
              css={{
                display: "flex",
                margin: "auto",
              }}
            />
          </Link>
        )}
        <GridTemplate variant="sitemap">
          {navigations?.map((navigation: ContentfulNavigation) => {
            const { id } = model

            return (
              <Navigation
                key={createUuid(id)}
                model={navigation}
                options={options}
                variant={variant}
              />
            )
          })}
        </GridTemplate>
      </Container>
    </Analytics>
  )
}
