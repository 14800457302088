import type { ContentfulGlobals } from "../contentful/contentful-globals"
import type { ContentfulNavigationSection } from "../contentful/contentful-navigation-section"
import { NavigationFooter } from "./navigation-footer"
import { NavigationHeader } from "./navigation-header"
import { NavigationSitemap } from "./navigation-sitemap"

export type NavigationSectionProps = {
  model: ContentfulNavigationSection
  globals: ContentfulGlobals
}

export const NavigationSection: React.FC<NavigationSectionProps> = ({
  model,
  globals,
}) => {
  switch (model.variant) {
    case "header":
      return <NavigationHeader model={model} />

    case "footer":
      return <NavigationFooter model={model} globals={globals} />

    case "sitemap":
      return <NavigationSitemap model={model} />

    default:
      return null
  }
}
