export const topicVariantContract = {
  block: {},
  card: {},
  divider: {},
  featured: {},
  headline: {},
  quote: {},
}

export const textVariantContract = {
  text: {},
}

export const navigationVariantContract = {
  header: {},
  footer: {},
  sitemap: {},
}
