import * as React from "react"

import { createUuid } from "../../lib/create-uuid"
import { ContentfulTopic } from "../contentful/contentful-topic"
import type {
  ContentfulTopicSection,
} from "../contentful/contentful-topic-section"
import { DataProps } from "./section"
import { Topic } from "./topic"
import { useTopicOptions } from "../../lib/use-topic-options"
import { TopicSectionContainer } from './topic-section-container'

export type TopicSectionProps = {
  model: ContentfulTopicSection
  data: DataProps
}

export const TopicSection: React.FC<TopicSectionProps> = ({ model, data }) => {
  const {
    abstract,
    action,
    eventId,
    heading,
    icon,
    media,
    reversed,
    scrollable,
    topicOptions,
    topics,
    variant,
  } = model

  /** Deprecated model */
  const deprecatedTopicOptions = useTopicOptions(topicOptions)

  const options = {
    abstract: abstract ?? deprecatedTopicOptions.abstract,
    action: action ?? deprecatedTopicOptions.action,
    heading: heading ?? deprecatedTopicOptions.heading,
    icon: icon ?? deprecatedTopicOptions.icon,
    media: media ?? deprecatedTopicOptions.media,
    reversed: reversed ?? !deprecatedTopicOptions.reversed,
    scrollable,
  }

  return (
    <TopicSectionContainer variant={variant} options={options} eventId={eventId}>
      {topics?.map((topic: ContentfulTopic, index) => {
        const { id } = model

        return (
          <Topic
            key={createUuid(id)}
            model={topic}
            options={options}
            data={{ ...data, topicIndex: index }}
            variant={variant}
          />
        )
      })}
    </TopicSectionContainer>
  )
}
