import { createUuid } from "../../lib/create-uuid"
import { Analytics } from "../layout/analytics"
import type {
  AdvertisementSectionVariant,
  ContentfulAdvertisementSection,
} from "../contentful/contentful-advertisement-section"
import { GridTemplate } from "../layout/grid-template"
import { GridTemplateStylesProps } from "../layout/grid-template.styles"
import { Advertisement } from "./advertisement"
import { useTopicOptions } from "../../lib/use-topic-options"

export type AdvertisementSectionProps = {
  model: ContentfulAdvertisementSection
}

export const AdvertisementSection: React.FC<AdvertisementSectionProps> = ({
  model,
}) => {
  const {
    abstract,
    action,
    eventId,
    heading,
    icon,
    media,
    reversed,
    advertisements,
    variant = "block",
    advertisementOptions,
  } = model

  /** Deprecated model */
  const deprecatedAdvertisementOptions = useTopicOptions(advertisementOptions)

  const options = {
    abstract: abstract ?? deprecatedAdvertisementOptions.abstract,
    action: action ?? deprecatedAdvertisementOptions.action,
    heading: heading ?? deprecatedAdvertisementOptions.heading,
    icon: icon ?? deprecatedAdvertisementOptions.icon,
    media: media ?? deprecatedAdvertisementOptions.media,
    reversed: reversed ?? !deprecatedAdvertisementOptions.reversed,
  }

  const gridVariants: Record<
    AdvertisementSectionVariant,
    GridTemplateStylesProps["variant"]
  > = {
    block: "advertisement",
    featured: "topic",
    "full-width": "topic",
  }

  return (
    <Analytics area="section" eventId={eventId ?? ""} variant="advertisement">
      <GridTemplate variant={gridVariants[variant]}>
        {advertisements?.map(advertisement => {
          const { id } = model

          return (
            <Advertisement
              key={createUuid(id)}
              model={advertisement}
              options={options}
              variant={variant}
            />
          )
        })}
      </GridTemplate>
    </Analytics>
  )
}
