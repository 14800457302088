import type { CSSObject } from "@emotion/react"
import { theme } from "../../styles/global-css-variables.css"

export const shelfStyles: CSSObject = {
  alignItems: "center",
  justifyContent: "center",
  display: "grid",
  ":hover > button": {
    transform: "scale(1)",
    opacity: 1,
  },
}

export const sliderStyles: CSSObject = {
  "::-webkit-scrollbar": {
    display: "none",
  },
  WebkitOverflowScrolling: "touch",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  scrollSnapType: "x mandatory",
  scrollBehavior: "smooth",
  overflowX: "scroll",
  overscrollBehaviorX: "contain",
  width: "100%",
  paddingTop: theme.spacing.large,
  paddingBottom: theme.spacing.large,
}

export const ulStyles: CSSObject = {
  display: "flex",
  flexWrap: "nowrap",
  whiteSpace: "nowrap",
  listStyle: "none",
  width: "100%",
  padding: 0,
  margin: 0,
  gap: theme.spacing.large,
}
