import type { CSSObject } from "@emotion/react"
import { mediaQuery } from "../../styles/media-query"
import type { Variants, Options, StylesProps } from "../../styles/types"
import type { AdvertisementSectionVariant } from "../contentful/contentful-advertisement-section"

type VariantStyle = AdvertisementSectionVariant
type OptionStyle = Options<"">

const base: CSSObject = {
  flex: "1",
  textAlign: "center",
  padding: 24,
  justifyContent: "start",
}

const variants: Variants<VariantStyle> = {
  block: {},
  featured: {},
  "full-width": {},
}

type AdvertisementContentStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const advertisementContentStyles: StylesProps<
  AdvertisementContentStylesProps
> = ({ variant, options }) => {
  return mediaQuery([base, variants[variant], options])
}
