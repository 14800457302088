import { CSSObject } from "@emotion/react"
import { theme } from "../../styles/global-css-variables.css"
import { mediaQuery } from "../../styles/media-query"
import type { Variants } from "../../styles/types"
import { focusStyles } from "../models/text-section.styles"

type VariantStyle = "next" | "previous"
type OptionStyle = ""

const base: CSSObject = {
  transition: "all .25s ease",
  transform: "scale(.8)",
  opacity: 0,
  position: "absolute",
  cursor: "pointer",
  borderRadius: 999,
  zIndex: 99,
  color: "white",
  backgroundColor: "rgba(0,0,0,.2)",
  lineHeight: 1,
  height: 52,
  width: 52,
  textAlign: "center",
  ":focus": {
    opacity: 1,
    transform: "scale(1)",
    ...focusStyles,
    borderRadius: 999,
  },
}

const variants: Variants<VariantStyle> = {
  next: {
    right: theme.spacing.large,
  },
  previous: {
    left: theme.spacing.large,
  },
}

export type ShelfButtonStylesProps = {
  direction: VariantStyle
  options?: OptionStyle
}

export const shelfButtonStyles = ({ direction }: ShelfButtonStylesProps) => {
  return mediaQuery([base, variants[direction]])
}

export const visuallyHiddenStyles: CSSObject = {
  position: "absolute",
  clip: "rect(1px,1px,1px,1px)",
  WebkitClipPath: "inset(0 0 99.9% 99.9%)",
  clipPath: "inset(0 0 99.9% 99.9%)",
  overflow: "hidden",
  height: 1,
  width: 1,
  padding: 0,
  border: 0,
}
