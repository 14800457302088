import { Heading } from "../elements/heading"
import { ContentfulExercise } from "../contentful/contentful-exercise"
import {
  ExerciseSectionOptions,
  ExerciseSectionVariant,
} from "../contentful/contentful-exercise-section"
import { Abstract } from "../elements/abstract"
import { AbstractStylesProps } from "../elements/abstract.styles"
import { Group } from "../layout/group"
import { HeadingStylesProps } from "../elements/heading.styles"
import { exerciseContentStyles } from "./exercise-content.styles"
import { ExerciseIcon } from "./exercise-icon"
import { theme } from "../../styles/global-css-variables.css"

export type ExerciseProps = {
  index: number
  model: ContentfulExercise
  options: ExerciseSectionOptions
  variant: ExerciseSectionVariant
}

export const ExerciseContent: React.FC<ExerciseProps> = ({
  index,
  model,
  options,
  variant,
}) => {
  const { abstract, heading, icon, reps, sets } = model

  const headingVariants: Record<
    ExerciseSectionVariant,
    HeadingStylesProps["variant"]
  > = {
    block: "medium",
  }

  const abstractVariants: Record<
    ExerciseSectionVariant,
    AbstractStylesProps["variant"]
  > = {
    block: "large",
  }

  const abstractOptions: AbstractStylesProps["options"] = {
    parse: ["block"].includes(variant),
  }

  return (
    <Group
      css={exerciseContentStyles({ variant })}
      options={{ space: true }}
      variant="column"
    >
      <Group variant="column">
        {options.icon && icon && (
          <ExerciseIcon model={icon} variant={variant} />
        )}
        {options.heading && heading && (
          <Heading is="h2" variant={headingVariants[variant]}>
            {index + 1 + `. `}
            {heading}
          </Heading>
        )}
        {(reps || sets) && (
          <div css={{ display: "flex" }}>
            <span
              css={{
                fontFamily: theme.font.family.body,
                color: theme.colors.light,
                background: theme.colors.link,
                padding: "4px 10px",
                borderRadius: 6,
              }}
            >
              {reps + `x • ` + sets + ` sets`}
            </span>
          </div>
        )}
        {abstract && (
          <Abstract
            variant={abstractVariants[variant]}
            options={abstractOptions}
          >
            {abstract.abstract}
          </Abstract>
        )}
      </Group>
    </Group>
  )
}
