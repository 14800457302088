import type { CSSObject } from "@emotion/react"
import { mediaQuery } from "../../styles/media-query"
import type { Variants, Options, StylesProps } from "../../styles/types"
import type { ExerciseSectionVariant } from "../contentful/contentful-exercise-section"

type VariantStyle = ExerciseSectionVariant
type OptionStyle = Options<"">

const base: CSSObject = {
  position: "relative",
}

const variants: Variants<VariantStyle> = {
  block: {
    display: "flex",
    flexDirection: "column",
  },
}

export type ExerciseStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const exerciseStyles: StylesProps<ExerciseStylesProps> = ({
  variant,
}) => {
  return mediaQuery([base, variants[variant]])
}
