import { theme } from "../../styles/global-css-variables.css"
import { mediaQuery } from "../../styles/media-query"
import type { CSSObject } from "@emotion/react"
import type { StylesProps, Variants } from "../../styles/types"

type VariantStyle = "compact" | "extended"

const base: CSSObject = {
  zIndex: 1,
  li: {
    position: "relative",
    padding: "16px 0",
    borderBottom: "1px solid #eee",
    ":hover": {
      backgroundColor: "#f9f9f9",
    },
    ":hover h3": {
      textDecoration: "underline",
    },
  },
  h3: {
    fontFamily: theme.font.family.body,
    fontSize: theme.font.size.default,
    color: theme.colors.link,
    margin: 0,
  },
  p: {
    fontFamily: theme.font.family.body,
    fontSize: theme.font.size.default,
    lineHeight: 1.45,
    marginBottom: 8,
    marginTop: 8,
  },
  a: {
    fontFamily: theme.font.family.body,
    fontSize: theme.font.size.small,
    color: "#999",
    ":after": {
      position: "absolute",
      content: `""`,
      zIndex: 1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
}

const compact: CSSObject = {
  position: "absolute",
  border: "1px solid #ccc",
  width: "calc(100% - 48px)",
  borderRadius: 8,
  overflow: "hidden",
  boxShadow:
    "0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ",
  li: {
    background: "#fff",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
    padding: 8,
  },
  h3: {
    display: "inline",
    fontSize: theme.font.size.small,
    paddingRight: 8,
  },
  p: {
    display: "inline",
    fontSize: theme.font.size.small,
  },
}

const variants: Variants<VariantStyle> = {
  extended: {},
  compact,
}

export type SearchResultsStylesProps = {
  variant: VariantStyle
}

export const searchResultsStyles: StylesProps<SearchResultsStylesProps> = ({
  variant,
}) => {
  return mediaQuery([base, variants[variant]])
}
