import * as React from "react"

import { exerciseMediaStyles } from "../models/exercise-media.styles"
import { getSingleAsset } from "./get-single-asset"
import type { AssetStylesProps } from "../models/asset.styles"
import type { ExerciseSectionVariant } from "../contentful/contentful-exercise-section"
import type { ContentfulAsset } from "../contentful/contentful-asset"

export type ExerciseSingleMediaProps = {
  model: ContentfulAsset
  variant: ExerciseSectionVariant
  poster: ContentfulAsset
}

export const ExerciseSingleMedia: React.FC<ExerciseSingleMediaProps> = ({
  model,
  variant,
  poster,
}) => {
  const variants: Record<ExerciseSectionVariant, AssetStylesProps["variant"]> =
    {
      block: "small",
    }

  const options = {
    square: undefined,
    wide: ["block"].includes(variant),
    display: undefined,
  }

  const styles = exerciseMediaStyles({ variant })

  return (
    <div css={styles}>
      {getSingleAsset({
        asset: model,
        poster,
        variant: variants[variant],
        options,
      })}
    </div>
  )
}
