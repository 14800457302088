import { Heading } from "../elements/heading"
import type { ContentfulAdvertisement } from "../contentful/contentful-advertisement"
import type {
  AdvertisementSectionOptions,
  AdvertisementSectionVariant,
} from "../contentful/contentful-advertisement-section"
import { Abstract } from "../elements/abstract"
import { AbstractStylesProps } from "../elements/abstract.styles"
import { Group } from "../layout/group"
import { HeadingStylesProps } from "../elements/heading.styles"
import { AdvertisementActions } from "./advertisement-actions"
import { advertisementContentStyles } from "./advertisement-content.styles"
import { AdvertisementIcon } from "./advertisement-icon"

export type AdvertisementContentProps = {
  model: ContentfulAdvertisement
  options: AdvertisementSectionOptions
  variant: AdvertisementSectionVariant
}

export const AdvertisementContent: React.FC<AdvertisementContentProps> = ({
  model,
  options,
  variant,
}) => {
  const { abstract, actions, heading, icon } = model

  const headingVariants: Record<
    AdvertisementSectionVariant,
    HeadingStylesProps["variant"]
  > = {
    block: "medium",
    featured: "medium",
    "full-width": "medium",
  }

  const abstractVariants: Record<
    AdvertisementSectionVariant,
    AbstractStylesProps["variant"]
  > = {
    block: "medium",
    featured: "medium",
    "full-width": "medium",
  }

  const abstractOptions: AbstractStylesProps["options"] = {
    parse: false,
  }

  return (
    <Group
      css={advertisementContentStyles({ variant })}
      options={{ space: true }}
      variant="column"
    >
      <Group variant="column">
        {options.icon && icon && (
          <AdvertisementIcon model={icon} variant={variant} />
        )}
        {options.heading && heading && (
          <Heading is="h3" variant={headingVariants[variant]}>
            {heading}
          </Heading>
        )}
        {options.abstract && abstract && (
          <Abstract
            variant={abstractVariants[variant]}
            options={abstractOptions}
          >
            {abstract.abstract}
          </Abstract>
        )}
      </Group>
      {options.action && actions && (
        <AdvertisementActions model={actions} variant={variant} />
      )}
    </Group>
  )
}
