import type { CSSObject } from "@emotion/react"
import { topicVariantContract } from "../../styles/contracts"
import { theme } from "../../styles/global-css-variables.css"
import { mediaQuery } from "../../styles/media-query"
import type { Variants, Options, StylesProps } from "../../styles/types"
import type { NavigationSectionVariant } from "../contentful/contentful-navigation-section"
import type { TopicSectionVariant } from "../contentful/contentful-topic-section"
import { scrollMarginTop } from "../models/text-section.styles"

type VariantStyle =
  | TopicSectionVariant
  | NavigationSectionVariant
  | "text"
  | "section"
  | "advertisement"
  | "exercise"
  | "topic"
  | "calendly"
type OptionStyle = Options<"fixed" | "border" | "scrollable">

const base: CSSObject = {
  /** Dark mode */
  // "@media (prefers-color-scheme: dark)": {
  //   backgroundColor: theme.colors.text,
  // },
  // '&[data-theme="dark"]': {
  //   backgroundColor: theme.colors.dark,
  // },
  paddingLeft: theme.spacing.large,
  paddingRight: theme.spacing.large,
  ...scrollMarginTop,
}

const variants: Variants<VariantStyle> = {
  header: {
    backgroundColor: theme.colors.light,
    "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
      backdropFilter: ["none", "none", "saturate(180%) blur(20px)"],
      background: [
        theme.colors.light,
        theme.colors.light,
        "rgba(255, 255, 255, 0.8)",
      ],
    },
    borderBottom: `1px solid transparent`,
    height: 64,
  },
  sitemap: {
    borderTop: `2px solid ${theme.colors.button}`,
    background: theme.colors.section,
  },
  footer: {
    background: theme.colors.section,
    borderTop: `1px solid transparent`,
  },
  ...topicVariantContract,
  block: {},
  card: {},
  text: {},
  section: {},
  exercise: {},
  advertisement: {},
  topic: {},
  calendly: {
    padding: 0,
  },
}

const border: CSSObject = {
  borderColor: theme.colors.border,
}

const fixed: CSSObject = {
  maxWidth: 1380,
  margin: "auto",
}

const scrollable: CSSObject = {
  padding: 0,
}

export type AnalyticsStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const analyticsStyles: StylesProps<AnalyticsStylesProps> = ({
  variant,
  options,
}) => {
  return mediaQuery([
    base,
    variants[variant],
    options?.border && border,
    options?.fixed && fixed,
    options?.scrollable && scrollable,
  ])
}
