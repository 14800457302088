import * as React from "react"

import { createUuid } from "../../lib/create-uuid"
import { SkipToContent } from "../elements/skip-to-content"

type GetSemanticTag = {
  section: {
    model: Record<string, unknown>
  }
  count: number
  index: number
}

const getSemanticTag = ({ section, count, index }: GetSemanticTag) => {
  const {
    model: { __typename, variant },
  } = section

  if (
    index === 0 &&
    __typename === "ContentfulNavigationSection" &&
    variant === "header"
  ) {
    return "header"
  }

  if (
    (index === count && __typename === "ContentfulNavigationSection") ||
    (index === count - 1 &&
      __typename === "ContentfulNavigationSection" &&
      variant === "sitemap")
  ) {
    return "footer"
  }

  return "main"
}

type SemanticElementsProps = {
  children?: (JSX.Element | null)[]
  skipToContent?: string
}

const SemanticElements: React.FC<SemanticElementsProps> = props => {
  const sections: Record<string, React.ReactNode[]> = {
    footer: [],
    header: [],
    main: [],
  }

  React.Children.forEach(props.children, (child, index) => {
    if (!child) {
      return null
    }
    sections[
      getSemanticTag({
        section: child.props.children?.props,
        count: React.Children.count(props.children) - 1,
        index,
      })
    ].push(
      <React.Fragment key={createUuid(`${index}`)}>{child}</React.Fragment>
    )
  })

  return (
    <React.Fragment>
      <SkipToContent>{props.skipToContent ?? "Skip to content"}</SkipToContent>
      {sections.header.length > 0 && (
        <header
          role="banner"
          css={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1002,
            height: 64,
          }}
        >
          {sections.header}
        </header>
      )}
      {sections.main.length > 0 && (
        <main role="main" id="content" css={{ paddingTop: 61 }}>
          {sections.main}
        </main>
      )}
      {sections.footer.length > 0 && (
        <footer role="contentinfo">{sections.footer}</footer>
      )}
    </React.Fragment>
  )
}

export default SemanticElements
