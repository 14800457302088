import * as React from "react"
import * as styles from "./shelf.styles"
import { getObserver } from "../../lib/get-observer"
import type { ShelfForwardedRefProps, ShelfProps } from "./shelf.types"
import { Analytics } from "./analytics"
import { ShelfItem } from "./shelf-item"
import { ShelfButton } from "./shelf-button"

export const Shelf = React.forwardRef(
  (
    {
      children,
      eventId,
      variant,
      onScrollEnd,
      onScrollStart,
      onSlidesVisibilityChange,
      onSlideVisible,
    }: ShelfProps,
    ref: React.ForwardedRef<ShelfForwardedRefProps>
  ) => {
    const [isScrolling, setIsScrolling] = React.useState(false)
    const scrollTimeout = React.useRef<NodeJS.Timeout | null>(null)
    const sliderRef = React.useRef<HTMLDivElement>(null)
    const slideRefs = React.useRef<HTMLLIElement[]>([])
    const buttonPrevRef = React.useRef<HTMLButtonElement>(null)
    const buttonNextRef = React.useRef<HTMLButtonElement>(null)
    const observer = React.useRef<IntersectionObserver>(null)
    const lastVisibleSlideIndex = React.useRef(0)
    const medianVisibleSlideIndex = React.useRef(0)
    const visibleSlidesIndices = React.useRef<number[]>([])
    const intersectionThreshold = 0.5
    const hideButtonThreshold = 30

    const addNode = React.useCallback((node: HTMLLIElement, index: number) => {
      slideRefs.current[index] = node
    }, [])

    const getSlideWidth = React.useCallback(
      () =>
        (sliderRef.current?.firstChild?.firstChild as HTMLUListElement)
          ?.clientWidth || 0,
      []
    )

    const intersectionCallback = React.useCallback(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          const target = entry.target as HTMLUListElement
          const index = Number(target.dataset.indexNumber)

          if (entry.intersectionRatio >= intersectionThreshold) {
            lastVisibleSlideIndex.current = index
            visibleSlidesIndices.current.push(index)
            visibleSlidesIndices.current.sort()

            slideRefs.current[index]?.setAttribute("aria-hidden", "false")

            onSlideVisible && onSlideVisible(index)

            return
          }

          visibleSlidesIndices.current = visibleSlidesIndices.current.filter(
            item => item !== index
          )
          slideRefs.current[index]?.setAttribute("aria-hidden", "true")
        })

        medianVisibleSlideIndex.current =
          visibleSlidesIndices.current[
            Math.floor(visibleSlidesIndices.current.length / 2)
          ]

        onSlidesVisibilityChange &&
          onSlidesVisibilityChange(medianVisibleSlideIndex.current)
      },
      []
    )

    const isSliderScrollable = React.useCallback(() => {
      if (!sliderRef.current) return false

      const sliderWidth = sliderRef.current.clientWidth
      const slideWidth = getSlideWidth() - 1

      return slideRefs.current.length * slideWidth > sliderWidth
    }, [])

    const manualScroll = (direction: "previous" | "next") => {
      const dir = direction === "previous" ? -1 : 1

      if (sliderRef.current) {
        const slideWidth = getSlideWidth()
        const slidesToScroll = Math.floor(
          sliderRef.current.clientWidth / slideWidth
        )
        sliderRef.current.scrollBy({
          top: 0,
          behavior: "smooth",
          left: slidesToScroll * slideWidth * dir,
        })
      }
    }

    const onSliderScroll = () => {
      scrollTimeout.current && clearTimeout(scrollTimeout.current)
      scrollTimeout.current = setTimeout(() => {
        scrollTimeout.current = null
        setIsScrolling(false)
        onScrollEnd && onScrollEnd(medianVisibleSlideIndex.current)
      }, 250)

      if (!isScrolling) {
        setIsScrolling(true)
      }
    }

    const scrollTo = React.useCallback((left: number) => {
      if (!sliderRef.current) return

      sliderRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
        left,
      })
    }, [])

    const scrollToSlide = React.useCallback((index: number) => {
      if (!sliderRef.current) return

      const sliderScrollLeft = sliderRef.current.scrollLeft
      const sliderWidth = sliderRef.current.clientWidth
      const slideWidth = getSlideWidth()
      const slideLeft = slideWidth * index

      // only scroll to the left if target slide is outside of view to the left
      if (slideLeft < sliderScrollLeft) {
        scrollTo(slideLeft)
        return
      }

      // only scroll to the right if target slide is outside of view to the right
      if (slideLeft + slideWidth > sliderScrollLeft + sliderWidth) {
        scrollTo(slideLeft + slideWidth - sliderWidth)
      }
    }, [])

    React.useImperativeHandle(ref, () => ({
      scrollToSlide,
      sliderRef,
    }))

    React.useEffect(() => {
      if (observer.current) observer.current.disconnect()

      const newObserver = getObserver(
        sliderRef.current,
        observer,
        intersectionCallback,
        intersectionThreshold
      )

      for (const node of slideRefs.current) {
        if (node) {
          newObserver.observe(node)
        }
      }

      return () => newObserver.disconnect()
    }, [React.Children.count(children)])

    React.useEffect(() => {
      if (!isScrolling) return

      onScrollStart && onScrollStart(medianVisibleSlideIndex.current)
    }, [isScrolling])

    React.useEffect(() => {
      if (
        !sliderRef.current ||
        !buttonNextRef.current ||
        !buttonPrevRef.current
      )
        return

      if (!isSliderScrollable()) {
        buttonNextRef.current.style.display = "none"
        buttonPrevRef.current.style.display = "none"
        return
      }

      if (sliderRef.current.scrollLeft <= hideButtonThreshold) {
        buttonNextRef.current.style.display = "block"
        buttonPrevRef.current.style.display = "none"
      } else if (
        sliderRef.current.clientWidth + sliderRef.current.scrollLeft >=
        sliderRef.current.scrollWidth - hideButtonThreshold
      ) {
        buttonPrevRef.current.style.display = "block"
        buttonNextRef.current.style.display = "none"
      } else {
        buttonNextRef.current.style.display = "block"
        buttonPrevRef.current.style.display = "block"
      }
    }, [React.Children.count(children), isScrolling])

    return (
      <Analytics
        area="section"
        eventId={eventId}
        variant={variant}
        options={{ scrollable: true }}
        css={styles.shelfStyles}
      >
        <div
          css={styles.sliderStyles}
          onScroll={onSliderScroll}
          ref={sliderRef}
        >
          <ul css={styles.ulStyles}>
            {React.Children.map(children, (child, index) => (
              <ShelfItem
                key={index}
                variant={variant}
                ref={(node: HTMLLIElement) => addNode(node, index)}
              >
                {child}
              </ShelfItem>
            ))}
          </ul>
        </div>
        <ShelfButton
          ref={buttonPrevRef}
          direction="previous"
          onClick={() => manualScroll("previous")}
        />
        <ShelfButton
          ref={buttonNextRef}
          direction="next"
          onClick={() => manualScroll("next")}
        />
      </Analytics>
    )
  }
)
