import { Action } from "../elements/action"
import { createUuid } from "../../lib/create-uuid"
import { Group } from "../layout/group"
import { topicActionsStyles } from "./topic-actions.styles"
import type { ActionStylesProps } from "../elements/action.styles"
import type { ContentfulAction } from "../contentful/contentful-action"
import type { GroupStylesProps } from "../layout/group.styles"
import type { TopicSectionVariant } from "../contentful/contentful-topic-section"

export type TopicActionsProps = {
  model: ContentfulAction[]
  variant: TopicSectionVariant
}

export const TopicActions: React.FC<TopicActionsProps> = ({
  model,
  variant,
}) => {
  const groupOptions: GroupStylesProps["options"] = {
    reverse: undefined,
    center: ["headline", "quote"].includes(variant),
    space: undefined,
  }

  const actionOptions: ActionStylesProps["options"] = {
    large: ["headline"].includes(variant),
    expand: ["card"].includes(variant),
  }

  const sliceNumber = ["card"].includes(variant) ? 1 : 2
  const styles = topicActionsStyles({ variant })

  return (
    <Group css={styles} options={groupOptions} variant="row">
      {model.slice(0, sliceNumber).map((action: ContentfulAction, index) => {
        const { id } = action

        const variant = index === 0 ? "primary" : "secondary"

        return (
          <Action
            key={createUuid(id)}
            model={action}
            options={actionOptions}
            variant={variant}
          />
        )
      })}
    </Group>
  )
}
