import * as React from "react"

import { Analytics } from "../layout/analytics"
import type {
  TopicSectionOptions,
  TopicSectionVariant,
} from "../contentful/contentful-topic-section"
import { GridTemplate } from "../layout/grid-template"
import { GridTemplateStylesProps } from "../layout/grid-template.styles"
import { Shelf } from "../layout/shelf"

type TopicSectionContainerProps = {
  children: React.ReactNode
  options: TopicSectionOptions
  variant: TopicSectionVariant
  eventId: string
}

export const TopicSectionContainer: React.FC<TopicSectionContainerProps> = ({
  children,
  options,
  variant,
  eventId,
}) => {
  const gridVariants: Record<
    TopicSectionVariant,
    GridTemplateStylesProps["variant"]
  > = {
    block: "block",
    card: "card",
    featured: "topic",
    headline: "topic",
    quote: "topic",
  }

  if (options.scrollable) {
    return (
      <Shelf eventId={eventId} variant={variant}>
        {children}
      </Shelf>
    )
  }

  return (
    <Analytics area="section" eventId={eventId} variant={variant}>
      <GridTemplate variant={gridVariants[variant]}>{children}</GridTemplate>
    </Analytics>
  )
}
