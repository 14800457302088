import { createUuid } from "../../lib/create-uuid"
import { Analytics } from "../layout/analytics"
import { ContentfulExercise } from "../contentful/contentful-exercise"
import type {
  ContentfulExerciseSection,
  ExerciseSectionVariant,
} from "../contentful/contentful-exercise-section"
import { GridTemplate } from "../layout/grid-template"
import { GridTemplateStylesProps } from "../layout/grid-template.styles"
import { Exercise } from "./exercise"
import { useTopicOptions } from "../../lib/use-topic-options"

export type ExerciseSectionProps = {
  model: ContentfulExerciseSection
}

export const ExerciseSection: React.FC<ExerciseSectionProps> = ({ model }) => {
  const {
    abstract,
    action,
    eventId,
    heading,
    icon,
    media,
    reversed,
    exercises,
    variant,
    exerciseOptions,
  } = model

  /** Deprecated model */
  const deprecatedTopicOptions = useTopicOptions(exerciseOptions)

  const options = {
    abstract: abstract ?? deprecatedTopicOptions.abstract,
    action: action ?? deprecatedTopicOptions.action,
    heading: heading ?? deprecatedTopicOptions.heading,
    icon: icon ?? deprecatedTopicOptions.icon,
    media: media ?? deprecatedTopicOptions.media,
    reversed: reversed ?? !deprecatedTopicOptions.reversed,
  }

  const gridVariants: Record<
    ExerciseSectionVariant,
    GridTemplateStylesProps["variant"]
  > = {
    block: "block",
  }

  return (
    <Analytics area="section" eventId={eventId} variant="exercise">
      <GridTemplate variant={gridVariants[variant]}>
        {exercises?.map((exercise: ContentfulExercise, index) => {
          const { id } = model

          return (
            <Exercise
              index={index}
              key={createUuid(id)}
              model={exercise}
              options={options}
              variant={variant}
            />
          )
        })}
      </GridTemplate>
    </Analytics>
  )
}
