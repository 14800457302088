import * as React from "react"

import { createUuid } from "../../lib/create-uuid"
import type { UseTypesOf } from "../../lib/use-types-of"
import type { TopicSectionVariant } from "../contentful/contentful-topic-section"
import { shelfItemStyles } from "./shelf-item.styles"

type ShelfItemProps = { variant: TopicSectionVariant } & UseTypesOf["li"]

export const ShelfItem = React.forwardRef(
  ({ children, variant }: ShelfItemProps, ref: React.Ref<HTMLLIElement>) => {
    return (
      <li
        css={shelfItemStyles({ variant })}
        key={createUuid("shelf-item")}
        ref={ref}
      >
        {children}
      </li>
    )
  }
)
