import * as React from "react"

import slugify from "@sindresorhus/slugify"
import { Action } from "../elements/action"
import { createUuid } from "../../lib/create-uuid"
import { Heading } from "../elements/heading"
import { navigationItemStyles } from "./navigation-item.styles"
import { navigationListStyles } from "./navigation-list.styles"
import { useGtag } from "../../lib/use-gtag"
import type { ContentfulAction } from "../contentful/contentful-action"
import type { ContentfulNavigation } from "../contentful/contentful-navigation"
import type {
  NavigationSectionOptions,
  NavigationSectionVariant,
} from "../contentful/contentful-navigation-section"
import type { UseTypesOf } from "../../lib/use-types-of"

export type NavigationProps = UseTypesOf["div"] & {
  model: ContentfulNavigation
  options: NavigationSectionOptions
  variant: NavigationSectionVariant
}

export const Navigation: React.FC<NavigationProps> = ({
  model,
  options,
  variant,
  ...props
}) => {
  const [triggered, setTriggered] = React.useState(false)
  const { heading, actions } = model

  const handleOnMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (triggered) return null
    setTriggered(!triggered)

    useGtag("event", "hover", {
      event_id: event.currentTarget.dataset.analyticsId,
      event_category: "engagement",
      non_interaction: true,
    })
  }

  const analyticsId = `navigation:${slugify(heading || "")}`

  return (
    <div
      data-analytics-id={analyticsId}
      onMouseEnter={handleOnMouseEnter}
      {...props}
    >
      {options.heading && heading && (
        <Heading variant="small" options={{ margin: true }}>
          {heading}
        </Heading>
      )}
      {actions && (
        <ul css={navigationListStyles({ variant })}>
          {actions?.map((action: ContentfulAction) => {
            const { id } = action

            return (
              <li css={navigationItemStyles({ variant })} key={createUuid(id)}>
                <Action variant="link" model={action} />
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}
