import type { CSSObject } from "@emotion/react"
import { mediaQuery } from "../../styles/media-query"
import type { Options, StylesProps, Variants } from "../../styles/types"
import { focusStyles } from "./text-section.styles"

/** A fallback for older browsers */
export const aspectRatioStyles: Variants<"display" | "wide"> = {
  display: {
    position: "relative",
    overflow: "hidden",
    height: 0,
    paddingBottom: "62.5%",
  },
  wide: {
    position: "relative",
    overflow: "hidden",
    height: 0,
    paddingBottom: "56.25%",
  },
}

type VariantStyle = "small" | "medium" | "large"
type OptionStyle = Options<"square" | "wide" | "display">

const base: CSSObject = {
  ":focus": focusStyles,
}

const variants: Variants<VariantStyle> = {
  small: {},
  medium: {},
  large: {},
}

const square: CSSObject = {
  aspectRatio: "1 / 1",
}

const wide: CSSObject = {
  aspectRatio: "16 / 9",
}

const display: CSSObject = {
  aspectRatio: "16 / 10",
}

export type AssetStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const assetStyles: StylesProps<AssetStylesProps> = ({
  variant,
  options,
}) => {
  return mediaQuery([
    base,
    variants[variant],
    options?.square && square,
    options?.wide && wide,
    options?.display && display,
  ])
}
