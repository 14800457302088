import * as React from "react"
import * as styles from "./text-section.styles"

import { Analytics } from "../layout/analytics"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
// import { createJumpLink } from "../../lib/create-jump-link"
import { createUuid } from "../../lib/create-uuid"
import { GridTemplate } from "../layout/grid-template"
import { Heading } from "../elements/heading"
import { Link } from "../elements/link"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import type { ContentfulTextSection } from "../contentful/contentful-text-section"
import type { Node } from "@contentful/rich-text-types"
import type { Options } from "@contentful/rich-text-react-renderer"

const options: Options = {
  renderMark: {
    [MARKS.BOLD]: text => <b css={styles.boldStyles}>{text}</b>,
    [MARKS.ITALIC]: text => <i css={styles.italicStyles}>{text}</i>,
    [MARKS.UNDERLINE]: text => <u css={styles.underlineStyles}>{text}</u>,
    [MARKS.CODE]: text => <code css={styles.codeStyles}>{text}</code>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <Link css={styles.anchorStyles} to={node.data.uri}>
          {children}
        </Link>
      )
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const entryHyperlink = node.data.target
      return (
        <Link css={styles.anchorStyles} to={entryHyperlink.slug}>
          {children}
        </Link>
      )
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <Heading is="h1" variant="xlarge">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Heading is="h2" variant="xlarge">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Heading is="h3" variant="large">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Heading is="h4" variant="large">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Heading is="h5" variant="large">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Heading is="h6" variant="large">
        {children}
      </Heading>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol css={styles.listStyles}>{children}</ol>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul css={styles.listStyles}>{children}</ul>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li css={styles.listItemStyles}>{children}</li>
    ),
    [BLOCKS.PARAGRAPH]: (
      node: Node & { content: { value?: string }[] },
      children
    ) => {
      if (node.content[0].value === "") {
        return null
      } else {
        return <p css={styles.paragraphStyles}>{children}</p>
      }
    },
    [BLOCKS.QUOTE]: (node, children) => (
      <blockquote css={styles.blockquoteStyles}>{children}</blockquote>
    ),
    [BLOCKS.HR]: () => <hr css={styles.hrStyles} />,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const embeddedAsset = node.data.target
      const sources = embeddedAsset.gatsbyImageData.images.sources ?? []
      const fallback = embeddedAsset.gatsbyImageData.placeholder?.fallback

      return (
        <picture>
          <source type={embeddedAsset.type} srcSet={sources[0].srcSet} />
          <img
            src={fallback}
            alt={embeddedAsset.description?.trim()}
            title={embeddedAsset.title?.trim()}
          />
        </picture>
      )
    },
  },
  renderText: text => {
    return text
      .split("\n")
      .reduce((children: React.ReactNode[], textSegment, index) => {
        return [
          ...children,
          index > 0 && <br key={createUuid(`${index}`)} />,
          textSegment,
        ]
      }, [])
  },
}

type TextSectionProps = {
  model: ContentfulTextSection
}

export const TextSection: React.FC<TextSectionProps> = ({ model }) => {
  const { text, eventId } = model

  const document = {
    raw: text.raw,
    references: text.references,
  }

  return (
    <Analytics area="section" eventId={eventId} variant="text">
      <GridTemplate variant="text">
        {renderRichText(document, options)}
      </GridTemplate>
    </Analytics>
  )
}
