import type { CSSObject } from "@emotion/react"
import { theme } from "../../styles/global-css-variables.css"

export const searchTabsStyles: CSSObject = {
  borderBottom: "1px solid #ccc",
}

export const searchTabStyles: CSSObject = {
  fontFamily: theme.font.family.body,
  fontSize: theme.font.size.medium,
  background: "none",
  border: "none",
  marginBottom: -1,
  marginRight: 16,
  padding: 0,
  paddingTop: theme.spacing.small,
  paddingBottom: theme.spacing.small,
  ":hover": {
    cursor: "pointer",
  },
  ":disabled": {
    color: theme.colors.link,
    borderBottom: `1px solid ${theme.colors.link}`,
  },
}
