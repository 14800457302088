import * as React from "react"

import { topicMediaStyles } from "../models/topic-media.styles"
import { getSingleAsset } from "./get-single-asset"
import type { AssetStylesProps } from "../models/asset.styles"
import type { TopicSectionVariant } from "../contentful/contentful-topic-section"
import type { ContentfulAsset } from "../contentful/contentful-asset"

export type TopicSingleMediaProps = {
  model: ContentfulAsset
  variant: TopicSectionVariant
  poster: ContentfulAsset
}

export const TopicSingleMedia: React.FC<TopicSingleMediaProps> = ({
  model,
  variant,
  poster,
}) => {
  const variants: Record<TopicSectionVariant, AssetStylesProps["variant"]> = {
    block: "small",
    card: "small",
    featured: "medium",
    headline: "large",
    quote: "large",
  }

  const options = {
    square: undefined,
    wide: ["block"].includes(variant),
    display: ["featured", "card"].includes(variant),
  }

  const styles = topicMediaStyles({ variant })

  return (
    <div css={styles}>
      {getSingleAsset({
        asset: model,
        poster,
        variant: variants[variant],
        options,
      })}
    </div>
  )
}
