import { isBrowser } from "./is-browser"

declare global {
  interface Window {
    gtag: (
      event: string,
      action: string,
      value: Partial<Record<string, string | number | symbol | boolean>>
    ) => void
  }
}

type UseGtagProps = {
  (
    event: "event",
    action: "view" | "hover" | "click",
    value: {
      event_id: string | undefined
      event_category: "engagement" | "ecommerce"
      event_label?: string | undefined
      /** @url https://support.google.com/analytics/answer/1033068#NonInteractionEvents */
      non_interaction?: boolean
    }
  ): void
}

/**
 * To send Google Analytics Events on a web page where
 * the global site tag has been added, use the gtag.js
 * event command with the following syntax:
 *
 * @url https://developers.google.com/analytics/devguides/collection/gtagjs/events
 *
 * ```
 * useGtag('event', <action>, {
 *   'event_id': <id>
 *   'event_category': <category>,
 *   'event_label': <label>,
 *   'non_interaction': <boolean>
 *   'value': <value>
 * });
 * ```
 */
export const useGtag: UseGtagProps = (event, action, value) => {
  if (isBrowser && window.gtag) {
    window.gtag(event, action, value)
  }
}
