import { mediaQuery } from "../../styles/media-query"
import type { CSSObject } from "@emotion/react"
import type { Variants, Options, StylesProps } from "../../styles/types"
import type { ExerciseSectionVariant } from "../contentful/contentful-exercise-section"

type VariantStyle = ExerciseSectionVariant
type OptionStyle = Options<"">

const base: CSSObject = {}

const variants: Variants<VariantStyle> = {
  block: {
    aspectRatio: "16 / 9",
    video: {
      display: "flex",
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
}

export type TopicMediaStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const exerciseMediaStyles: StylesProps<TopicMediaStylesProps> = ({
  variant,
}) => {
  return mediaQuery([base, variants[variant]])
}
