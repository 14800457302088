import * as React from "react"

import type { SiteMetadataProps } from "../../hooks/use-site-metadata"
import { useSiteMetadata } from "../../hooks/use-site-metadata"

type SeoProps = Partial<
  {
    siteTitle: string
    slug: string
  } & SiteMetadataProps
>

export const Seo: React.FC<SeoProps> = ({
  author,
  description,
  image,
  keywords,
  siteTitle,
  slug,
  title,
}) => {
  const {
    author: defaultAuthor,
    description: defaultDescription,
    image: defaultImage,
    keywords: defaultKeywords,
    siteUrl: defaultSiteUrl,
    title: defaultTitle,
  } = useSiteMetadata()

  const seo = {
    author: author ?? defaultAuthor,
    description: description ?? defaultDescription,
    keywords: keywords ?? defaultKeywords,
    title: `${title}${siteTitle && ` | ${siteTitle}`}` ?? defaultTitle,
    image: image
      ? `${image}?w=1600&h=840&q=90`
      : `${defaultSiteUrl}/${defaultImage}`,
    url: slug ? `${defaultSiteUrl}${slug}` : defaultSiteUrl,
  }

  return (
    <React.Fragment>
      <title key={React.useId()}>{seo.title}</title>
      <link key={React.useId()} rel="canonical" href={seo.url} />

      {/* Primary Meta Tags */}
      <meta key={React.useId()} name="title" content={seo.title} />
      <meta key={React.useId()} name="description" content={seo.description} />
      <meta key={React.useId()} name="image" content={seo.image} />
      <meta
        key={React.useId()}
        name="keywords"
        content={seo.keywords ? seo.keywords.join(", ") : undefined}
      />

      {/* Open Graph / Facebook  */}
      <meta key={React.useId()} property="og:type" content="website" />
      <meta key={React.useId()} property="og:url" content={seo.url} />
      <meta key={React.useId()} property="og:title" content={seo.title} />
      <meta
        key={React.useId()}
        property="og:description"
        content={seo.description}
      />
      <meta key={React.useId()} property="og:image" content={seo.image} />

      {/* Twitter */}
      <meta
        key={React.useId()}
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key={React.useId()} name="twitter:creator" content={seo.author} />
      <meta key={React.useId()} name="twitter:url" content={seo.url} />
      <meta key={React.useId()} name="twitter:title" content={seo.title} />
      <meta
        key={React.useId()}
        name="twitter:description"
        content={seo.description}
      />
      <meta key={React.useId()} name="twitter:image" content={seo.image} />
    </React.Fragment>
  )
}
