import * as React from "react"

import {
  ShelfButtonStylesProps,
  visuallyHiddenStyles,
} from "./shelf-button.styles"
import { shelfButtonStyles } from "./shelf-button.styles"
import { Icon } from "../elements/icon"

export type ShelfButtonProps = {
  onClick: () => void
  direction: ShelfButtonStylesProps["direction"]
}

export const ShelfButton = React.forwardRef(
  (
    { direction, onClick }: ShelfButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const icon = direction === "next" ? "chevron-right" : "chevron-left"
    const styles = shelfButtonStyles({ direction })

    return (
      <button
        type="button"
        aria-hidden="true"
        css={styles}
        onClick={onClick}
        ref={ref}
      >
        <Icon icon={icon} variant="medium" />
        <span css={visuallyHiddenStyles}>{direction}</span>
      </button>
    )
  }
)
