import * as React from "react"

import { assetStyles } from "../models/asset.styles"
import { GatsbyImage } from "gatsby-plugin-image"
import { useInView } from "react-intersection-observer"
import type { AssetStylesProps } from "../models/asset.styles"
import type { ContentfulAsset } from "../contentful/contentful-asset"

type SingleAssetProps = {
  asset: ContentfulAsset
  poster: ContentfulAsset
  variant: AssetStylesProps["variant"]
  options: AssetStylesProps["options"]
}

export const getSingleAsset: React.FC<SingleAssetProps> = ({
  asset,
  poster,
  variant,
  options,
}) => {
  const { ref, inView = false } = useInView({ triggerOnce: true })

  const { mimeType, description, title, url, gatsbyImageData } = asset

  const styles = assetStyles({ variant, options })

  const dimensions = {
    small: {
      height: 420,
      width: 800,
    },
    medium: {
      height: 630,
      width: 1200,
    },
    large: {
      height: 840,
      width: 1600,
    },
  }

  if (mimeType.includes("video/")) {
    let usePoster: string | undefined = undefined

    if (poster) {
      usePoster = `${poster.url}?q=90&w=${dimensions[variant].width}&h=${dimensions[variant].height}&fit=fill&f=center`
    }

    return (
      <video
        // autoPlay={true}
        controls={true}
        css={styles}
        loop={true}
        muted={true}
        playsInline={true}
        poster={usePoster}
        ref={ref}
      >
        {inView && (
          <React.Fragment>
            <source src={url ?? ""} type={mimeType} />
            Your browser does not support the video tag.
          </React.Fragment>
        )}
      </video>
    )
  }

  if (mimeType.includes("image/")) {
    return (
      <GatsbyImage
        css={styles}
        image={gatsbyImageData}
        alt={description ?? ""}
        title={title ?? ""}
      />
    )
  }

  return null
}
