import * as React from "react"

import slugify from "@sindresorhus/slugify"
import type { ContentfulTopic } from "../contentful/contentful-topic"
import type {
  TopicSectionOptions,
  TopicSectionVariant,
} from "../contentful/contentful-topic-section"
import { topicStyles } from "./topic.styles"
import { useGtag } from "../../lib/use-gtag"
import { TopicContent } from "./topic-content"
import { DataProps } from "./section"
import { TopicSingleMedia } from "../deprecated/topic-single-media"

export type TopicProps = {
  model: ContentfulTopic
  options: TopicSectionOptions
  variant: TopicSectionVariant
  data: DataProps
}

export const Topic: React.FC<TopicProps> = ({
  model,
  options,
  variant,
  data,
}) => {
  const [triggered, setTriggered] = React.useState(false)
  const { heading, singleMedia, poster } = model

  const handleOnMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (triggered) return null
    setTriggered(!triggered)

    useGtag("event", "hover", {
      event_id: event.currentTarget.dataset.analyticsId,
      event_category: "engagement",
      non_interaction: true,
    })
  }

  const analyticsId = `topic:${slugify(heading || "")}`
  const styles = topicStyles({ variant, options })

  return (
    <div
      css={styles}
      data-analytics-id={analyticsId}
      onMouseEnter={handleOnMouseEnter}
    >
      {options.media && singleMedia && (
        <TopicSingleMedia
          variant={variant}
          model={singleMedia}
          poster={poster}
        />
      )}
      <TopicContent
        model={model}
        options={options}
        data={data}
        variant={variant}
      />
    </div>
  )
}
