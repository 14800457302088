import facepaint from "facepaint"
import type { CSSObject } from "@emotion/react"
import type { StylesProps } from "../../styles/types"

const breakpoints = [698, 1048]

export const calendlyMediaQuery = facepaint(
  breakpoints.map(breakPoint => `@media (min-width: ${breakPoint}px)`)
)

const base: CSSObject = {}

const iframe: CSSObject = {
  height: [1200, 1400, 840],
  minWidth: 320,
  width: "100%",
}

export const iframeStyles: StylesProps = () => {
  return calendlyMediaQuery([base, iframe])
}
