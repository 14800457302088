import type { CSSObject } from "@emotion/react"
import { theme } from "../../styles/global-css-variables.css"
import { mediaQuery } from "../../styles/media-query"
import type { Variants, Options, StylesProps } from "../../styles/types"
import type { TopicSectionVariant } from "../contentful/contentful-topic-section"

type VariantStyle = TopicSectionVariant
type OptionStyle = Options<"reversed" | "scrollable">

const base: CSSObject = {
  position: "relative",
}

const variants: Variants<VariantStyle> = {
  card: {
    backgroundColor: theme.colors.light,
    border: theme.colors.border,
    borderRadius: 4,
    boxShadow: `0px 0px 16px ${theme.colors.border}`,
    display: "flex",
    flexDirection: "column",
    maxWidth: 327,
    minWidth: 327,
    overflow: "hidden",
    width: "100%",
    cursor: "pointer",
    transition: "all .3s cubic-bezier(0,0,.5,1)",
    ":hover, :focus": {
      transform: "scale(1.01)",
      boxShadow: "2px 4px 16px rgba(0,0,0,.16)",
    },
  },
  block: {
    display: "flex",
    flexDirection: "column",
  },
  featured: {
    alignItems: "center",
    display: "flex",
    flexDirection: ["column", "row"],
    justifyContent: "space-evenly",
    gap: theme.spacing.large,
    ":nth-of-type(even) > div:nth-of-type(2n)": {
      order: -1,
    },
  },
  headline: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  quote: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
}

const reversed: Variants<VariantStyle> = {
  block: {
    flexDirection: "column-reverse",
  },
  card: {
    flexDirection: "column-reverse",
  },
  quote: {
    flexDirection: "column-reverse",
  },
  featured: {
    flexDirection: ["column-reverse", "row-reverse"],
  },
  headline: {
    flexDirection: "column-reverse",
  },
}

const scrollable: Variants<VariantStyle> = {
  block: {
    maxWidth: 380,
  },
  card: {},
  quote: {
    maxWidth: 1380,
  },
  featured: {
    maxWidth: 1380,
  },
  headline: {
    maxWidth: 1380,
  },
}

export type TopicStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const topicStyles: StylesProps<TopicStylesProps> = ({
  variant,
  options,
}) => {
  return mediaQuery([
    base,
    variants[variant],
    options?.reversed && reversed[variant],
    options?.scrollable && scrollable[variant],
  ])
}
