import * as React from "react"

import { searchTabsStyles, searchTabStyles } from "./search-tabs.styles"

export type TabsProps = {
  handleClick: React.MouseEventHandler
  category: string
  model: {
    name: string
    value: string
  }[]
}

export const Tabs: React.FC<TabsProps> = props => {
  const { category, model, handleClick } = props
  const [currentCategory, setCurrentCategory] = React.useState("")

  React.useEffect(() => {
    setCurrentCategory(category)
  }, [category])

  return (
    <div css={searchTabsStyles}>
      {model.map((tab, index) => (
        <button
          key={`tab-` + index}
          type="button"
          value={tab.value}
          onClick={event => handleClick(event)}
          disabled={currentCategory === tab.value}
          css={searchTabStyles}
        >
          {tab.name}
        </button>
      ))}
    </div>
  )
}
