import * as React from "react"

import type { HeadProps, PageProps } from "gatsby"
import { graphql } from "gatsby"
import { Seo } from "../components/layout/seo"
import { Section } from "../components/models/section"
import { ContentfulPage } from "../components/contentful/contentful-page"
import { ContentfulGlobals } from "../components/contentful/contentful-globals"
import { CookieConsent } from "../components/layout/cookie-consent"

type PageQuery = {
  page: ContentfulPage
  globals: ContentfulGlobals
}

const Page: React.FC<PageProps<PageQuery, PageContext>> = ({
  data: { page, globals },
}) => {
  const sections = page.sections
  const cookieConsent = {
    banner: globals.cookieBanner,
    button: globals.cookieButton,
  }

  return (
    <React.Fragment>
      <Section model={sections} globals={globals} />
      <CookieConsent model={cookieConsent} />
    </React.Fragment>
  )
}
export default Page

export const Head: React.FC<HeadProps<PageQuery, PageContext>> = ({
  data: { page, globals },
  location: { pathname },
}) => {
  return (
    <Seo
      key={React.useId()}
      author={globals.siteAuthor}
      description={page.seoDescription ?? globals.siteDescription}
      image={page.seoImage?.url ?? globals.siteImage?.url}
      keywords={page.seoKeywords ?? globals.siteKeywords}
      siteTitle={globals.siteTitle}
      slug={page.slug ?? pathname}
      title={page.seoTitle}
    />
  )
}

type PageContext = {
  contentfulGlobalsId: string
  contentfulPageId: string
  locale: string
}

export const query = graphql`
  query (
    $contentfulGlobalsId: String
    $contentfulPageId: String
    $locale: String
  ) {
    page: contentfulPage(
      contentful_id: { eq: $contentfulPageId }
      node_locale: { eq: $locale }
    ) {
      ...page
    }
    globals: contentfulGlobals(
      contentful_id: { eq: $contentfulGlobalsId }
      node_locale: { eq: $locale }
    ) {
      ...globals
    }
  }
`
/** (SSR) Server-side renders at runtime (uses getServerData) */
// export async function getServerData() {}

/** (DSG) Deferred static generation - page generated at runtime */
// export async function config() {
//   // @ts-ignore
//   return ({ params }) => {
//     return {
//       defer: true
//     }
//   }
// }
