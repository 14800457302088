import * as React from "react"

import { graphql, useStaticQuery, navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { Tabs } from "./search-tabs"
import {
  searchSectionStyles,
  seachInputStyles,
  searchTermStyles,
  searchLabelStyles,
} from "./search-section.styles"
import { SearchResults } from "./search-results"
import type { ContentfulPage } from "../contentful/contentful-page"
import type { ContentfulExercise } from "../contentful/contentful-exercise"
import type { ContentfulSearchSection } from "../contentful/contentful-search-section"
import type { ContentfulExerciseSection } from "../contentful/contentful-exercise-section"

export type SearchProps = {
  model: ContentfulSearchSection
}

type PageQuery = ReadonlyArray<{ node: ContentfulPage }>

export const SearchSection: React.FC<SearchProps> = ({ model }) => {
  const { variant } = model

  const compactView = variant === "compact"

  const data = useStaticQuery(graphql`
    query SearchPageQuery {
      allContentfulPage(
        filter: { seoKeywords: { in: ["Oefeningen", "Aandoeningen", "Fysio"] } }
        sort: { fields: [seoTitle], order: [ASC] }
      ) {
        edges {
          node {
            node_locale
            id
            slug
            seoTitle
            seoDescription
            seoKeywords
            sections {
              ... on ContentfulExerciseSection {
                exercises {
                  heading
                }
                id
              }
            }
          }
        }
      }
    }
  `)

  const locale: string = process.env.GATSBY_HTML_LANG || "en-US"
  const pageQuery: PageQuery = data.allContentfulPage.edges
  const pageQueryByLocale: PageQuery = pageQuery.filter(
    ({ node }) => node.node_locale === locale,
  )
  const searchInput = React.useRef<HTMLInputElement>(null)
  // If exists use the String Query from location.search
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [query, setQuery] = React.useState(params.get("q") || "")
  const [searchQuery, setSearchQuery] = React.useState("")
  const [category, setCategory] = React.useState(params.get("c") || "")
  const [pages, setPages] = React.useState(pageQueryByLocale)
  const [content, setContent] = React.useState({
    inputPlaceholder: "",
    order: "",
    allResults: "",
    resultsFor: "",
    found: "",
    result: "",
    results: "",
  })

  React.useEffect(() => {
    // Set focus on Search input
    if (!compactView) searchInput?.current?.focus()
  }, [compactView])

  React.useEffect(() => {
    if (!query && !category) {
      setPages(pageQueryByLocale)
    } else {
      const filterByPageQuery = (
        pageQuery: PageQuery,
        query: string,
      ): PageQuery => {
        return pageQuery.filter(({ node }) => {
          return (
            node.seoTitle?.toLowerCase().includes(query.toLowerCase()) ||
            node.seoDescription?.toLowerCase().includes(query.toLowerCase())
          )
        })
      }

      const filterByPageCategory = (
        pageQuery: PageQuery,
        category: string,
      ): PageQuery => {
        return pageQuery.filter(({ node }) => {
          return node.seoKeywords?.includes(category)
        })
      }

      const filterByExercisesQuery = (
        pageQuery: PageQuery,
        query: string,
      ): PageQuery => {
        return pageQuery.filter(({ node }) => {
          return node.sections?.some(
            // @ts-ignore
            (section: ContentfulExerciseSection) => {
              if (section?.exercises) {
                return section.exercises.some(
                  (exercise: ContentfulExercise) => {
                    if (exercise) {
                      return exercise.heading
                        ?.toLowerCase()
                        .includes(query.toLowerCase())
                    }
                  },
                )
              }
            },
          )
        })
      }

      let filterQuery = pageQueryByLocale
      if (category) {
        filterQuery = filterByPageCategory(filterQuery, category)
      }

      const pageCollection = filterByPageQuery(filterQuery, query)
      const exercisesCollection = filterByExercisesQuery(filterQuery, query)

      setPages([...new Set([...pageCollection, ...exercisesCollection])])
    }
  }, [query, category])

  React.useEffect(() => {
    setContent({
      inputPlaceholder:
        locale === "nl-NL"
          ? "Zoek naar aandoeningen en oefeningen"
          : "Search for ailments or exercises for improving health",
      order:
        locale === "nl-NL"
          ? "op Alfabetische volgorde"
          : "by Alphabetical order",
      allResults: locale === "nl-NL" ? "Alle resultaten" : "All results",
      resultsFor: locale === "nl-NL" ? "Resultaten voor" : "Results for",
      found: locale === "nl-NL" ? "gevonden" : "found",
      result: locale === "nl-NL" ? "resultaten" : "results",
      results: locale === "nl-NL" ? "resultaat" : "result",
    })
  }, [])

  React.useEffect(() => {
    setSearchQuery(query)
  }, [query])

  const handleTabClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const value = event.currentTarget.value
    setCategory(value)
    params.delete("c")
    if (category || value) {
      params.append("c", value)
    }
    navigate(`${location.pathname}?${params}`, { replace: true })
  }

  const handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    setQuery(value)
  }

  const handleSearchParams = () => {
    if (!compactView) {
      params.delete("q")
      if (query) {
        params.append("q", query)
      }
      navigate(`${location.pathname}?${params}`, { replace: true })
    }
  }

  const listOfTabs = {
    "nl-NL": [
      { name: "Alle resultaten", value: "" },
      { name: "Aandoeningen", value: "Aandoeningen" },
      { name: "Oefeningen", value: "Oefeningen" },
    ],
    "en-US": [
      { name: "All results", value: "" },
      { name: "Ailments", value: "Aandoeningen" },
      { name: "Exercises", value: "Oefeningen" },
    ],
  }

  const categoryByLocale = (category: string, locale: string) => {
    if (locale === "en-US") {
      if (category === "Aandoeningen") {
        return "Ailments"
      }
      if (category === "Oefeningen") {
        return "Exercises"
      }
    } else {
      return category
    }
  }

  return (
    <div css={searchSectionStyles({ variant })}>
      <input
        css={seachInputStyles}
        type="search"
        value={query}
        onChange={handleSearchQuery}
        onKeyUp={handleSearchParams}
        className="input"
        placeholder={content.inputPlaceholder}
        ref={searchInput}
      />
      {!compactView && (
        <div css={searchTermStyles}>
          {!query ? (
            <React.Fragment>
              {category
                ? categoryByLocale(category, locale)
                : content.allResults}{" "}
              {content.order}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {content.resultsFor} {searchQuery}
            </React.Fragment>
          )}
        </div>
      )}
      {!compactView && (
        <Tabs
          model={locale === "nl-NL" ? listOfTabs["nl-NL"] : listOfTabs["en-US"]}
          category={category}
          handleClick={handleTabClick}
        />
      )}
      {!compactView && pages.length > 0 && (
        <div css={searchLabelStyles}>
          {pages.length} {pages.length === 1 ? content.results : content.result}{" "}
          {content.found}
        </div>
      )}
      {compactView && query && pages.length >= 0 && (
        <SearchResults
          variant={variant}
          pages={pages}
          query={query}
          location={location}
          locale={locale}
        />
      )}
      {!compactView && (!query || query) && pages.length >= 0 && (
        <SearchResults
          variant={variant}
          pages={pages}
          query={query}
          location={location}
          locale={locale}
        />
      )}
    </div>
  )
}
