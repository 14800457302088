import * as React from "react"
import * as styles from "./navigation-footer.styles"

import type { ContentfulNavigation } from "../contentful/contentful-navigation"
import type { ContentfulNavigationSection } from "../contentful/contentful-navigation-section"
import type { ContentfulGlobals } from "../contentful/contentful-globals"
import { createUuid } from "../../lib/create-uuid"
import { Navigation } from "./navigation"
import { Analytics } from "../layout/analytics"
import { Link } from "../elements/link"
import { GridTemplate } from "../layout/grid-template"

export type NavigationFooterProps = {
  model: ContentfulNavigationSection
  globals: ContentfulGlobals
}

export const NavigationFooter: React.FC<NavigationFooterProps> = ({
  model,
  globals,
}) => {
  const { branding, heading, eventId, navigations, variant } = model
  const { copyright } = globals

  const options = {
    branding,
    heading,
  }

  return (
    <Analytics
      area="section"
      eventId={eventId}
      options={{ border: true }}
      variant="footer"
    >
      <GridTemplate variant="footer">
        {navigations?.slice(0, 1).map((navigation: ContentfulNavigation) => {
          const { id } = model

          return (
            <Navigation
              key={createUuid(id)}
              model={navigation}
              options={options}
              variant={variant}
            />
          )
        })}
        <div css={styles.legalStyles}>
          <span css={styles.copyrightStyles}>
            Copyright © {new Date().getFullYear()} {copyright}
          </span>
          <Link
            to="https://github.com/knappsack/gatsby-starter-contentful"
            css={styles.supporterStyles}
          >
            Made with Knappsack
          </Link>
        </div>
      </GridTemplate>
    </Analytics>
  )
}
