import { aspectRatioStyles } from "./asset.styles"
import { mediaQuery } from "../../styles/media-query"
import { topicVariantContract } from "../../styles/contracts"
import type { CSSObject } from "@emotion/react"
import type { Variants, Options, StylesProps } from "../../styles/types"
import type { TopicSectionVariant } from "../contentful/contentful-topic-section"

type VariantStyle = TopicSectionVariant
type OptionStyle = Options<"">

const base: CSSObject = {}

const variants: Variants<VariantStyle> = {
  ...topicVariantContract,
  card: {
    ...aspectRatioStyles.display,
    video: {
      display: "flex",
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  block: {
    ...aspectRatioStyles.wide,
    video: {
      display: "flex",
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  featured: {
    display: "flex",
    width: "100%",
    picture: {
      margin: "auto",
    },
    video: {
      margin: "auto",
    },
  },
}

export type TopicMediaStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const topicMediaStyles: StylesProps<TopicMediaStylesProps> = ({
  variant,
}) => {
  return mediaQuery([base, variants[variant]])
}
