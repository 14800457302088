import type { AdvertisementSectionVariant } from "../contentful/contentful-advertisement-section"
import { Icon } from "../elements/icon"
import type { IconStylesProps } from "../elements/icon.styles"
import { advertisementIconStyles } from "./advertisement-icon.styles"

export type AdvertisementIconProps = {
  model: string
  variant: AdvertisementSectionVariant
}

export const AdvertisementIcon: React.FC<AdvertisementIconProps> = ({
  model,
  variant,
}) => {
  const variants: Record<
    AdvertisementSectionVariant,
    IconStylesProps["variant"]
  > = {
    block: "medium",
    featured: "large",
    "full-width": "large",
  }

  const styles = advertisementIconStyles({ variant })

  return (
    <div css={styles}>
      <Icon variant={variants[variant]} icon={model} />
    </div>
  )
}
