import type { CSSObject } from "@emotion/react"
import { mediaQuery } from "../../styles/media-query"
import type { Variants, Options, StylesProps } from "../../styles/types"
import type { AdvertisementSectionVariant } from "../contentful/contentful-advertisement-section"

type VariantStyle = AdvertisementSectionVariant
type OptionStyle = Options<"">

const base: CSSObject = {}

const variants: Variants<VariantStyle> = {
  block: {},
  featured: {},
  "full-width": {},
}

export type AdvertisementActionsStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const advertisementActionsStyles: StylesProps<
  AdvertisementActionsStylesProps
> = ({ variant }) => {
  return mediaQuery([base, variants[variant]])
}
