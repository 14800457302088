import type { CSSObject } from "@emotion/react"
import type { Variants } from "../../styles/types"
import { theme } from "../../styles/global-css-variables.css"
import { mediaQuery } from "../../styles/media-query"
import type { TopicSectionVariant } from "../contentful/contentful-topic-section"

type VariantStyle = TopicSectionVariant
type OptionStyle = ""

const base: CSSObject = {
  scrollSnapAlign: "center",
  display: "flex",
  justifyContent: "center",
  minWidth: "100%",
}

const width = {
  block: `380px`,
  card: `327px`,
}

const variants: Variants<VariantStyle> = {
  block: {
    minWidth: width.block,
    ":first-of-type": {
      minWidth: `calc(${width.block} + ${theme.spacing.large})`,
      justifyContent: "right",
    },
    ":last-of-type": {
      minWidth: `calc(${width.block} + ${theme.spacing.large})`,
      justifyContent: "left",
    },
    ":only-child": {
      minWidth: `calc(${width.block} + ${theme.spacing.xxlarge})`,
      justifyContent: "center",
    },
  },
  card: {
    minWidth: 327,
    ":first-of-type": {
      minWidth: `calc(${width.card} + ${theme.spacing.large})`,
      justifyContent: "right",
    },
    ":last-of-type": {
      minWidth: `calc(${width.card} + ${theme.spacing.large})`,
      justifyContent: "left",
    },
    ":only-child": {
      minWidth: `calc(${width.card} + ${theme.spacing.xxlarge})`,
      justifyContent: "center",
    },
  },
  featured: {},
  headline: {},
  quote: {},
}

export type ShelfItemStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const shelfItemStyles = ({ variant }: ShelfItemStylesProps) => {
  return mediaQuery([base, variants[variant]])
}
