import type { CSSObject } from "@emotion/react"
import { theme } from "../../styles/global-css-variables.css"
import { mediaQuery } from "../../styles/media-query"
import type { Variants, Options, StylesProps } from "../../styles/types"
import type { ExerciseSectionVariant } from "../contentful/contentful-exercise-section"

type VariantStyle = ExerciseSectionVariant
type OptionStyle = Options<"">

const base: CSSObject = {
  padding: theme.spacing.default,
  gap: theme.spacing.default,
}

const variants: Variants<VariantStyle> = {
  block: {
    flex: "1 1 auto",
  },
}

type ExerciseContentStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const exerciseContentStyles: StylesProps<ExerciseContentStylesProps> = ({
  variant,
  options,
}) => {
  return mediaQuery([base, variants[variant], options])
}
