import type { CSSObject } from "@emotion/react"
import { mediaQuery } from "../../styles/media-query"
import type { Variants, Options, StylesProps } from "../../styles/types"
import type { AdvertisementSectionVariant } from "../contentful/contentful-advertisement-section"

type VariantStyle = AdvertisementSectionVariant
type OptionStyle = Options<"">

const base: CSSObject = {
  aspectRatio: "16 / 9",
  video: {
    display: "flex",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}

const variants: Variants<VariantStyle> = {
  block: {},
  featured: {},
  "full-width": {},
}

export type AdvertisementMediaStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const advertisementMediaStyles: StylesProps<
  AdvertisementMediaStylesProps
> = ({ variant }) => {
  return mediaQuery([base, variants[variant]])
}
