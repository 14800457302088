import * as React from "react"

import mux from "mux-embed"
import Hls from "hls.js"

type MuxVideoPlayerProps = {
  videoId: string
  playbackId: string
  videoTitle: string
}

const MuxVideoPlayer: React.FC<MuxVideoPlayerProps> = ({
  playbackId,
  videoId,
  videoTitle,
}) => {
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const src = `https://stream.mux.com/${playbackId}.m3u8`

  React.useEffect(() => {
    const hls = new Hls()

    if (videoRef.current) {
      const video = videoRef.current
      const initTime = Date.now()

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = src
      } else if (Hls.isSupported()) {
        hls.loadSource(src)
        hls.attachMedia(video)
      }

      mux.monitor(video, {
        debug: false,
        hlsjs: hls,
        Hls,
        data: {
          env_key: "q1vkgp2cm23idfpmpnhb2ec7k",
          player_name: "Production Player",
          player_version: "1.0.0",
          player_init_time: initTime,
          video_id: videoId,
          video_title: videoTitle,
          video_series: "exercise",
        },
      })
    }

    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [videoId, src, videoRef, videoTitle])

  return (
    <video
      controls
      loop
      preload="none"
      ref={videoRef}
      poster={`https://image.mux.com/${playbackId}/thumbnail.png`}
      style={{ aspectRatio: "16 / 9", height: "auto", width: "100%" }}
    />
  )
}

export default MuxVideoPlayer
