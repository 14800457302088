import type { CSSObject } from "@emotion/react"
import { mediaQuery } from "../../styles/media-query"
import type { Options, StylesProps, Variants } from "../../styles/types"
import { theme } from "../../styles/global-css-variables.css"

type VariantStyle =
  | "advertisement"
  | "block"
  | "branding"
  | "card"
  | "footer"
  | "header"
  | "sitemap"
  | "text"
  | "topic"
type OptionStyle = Options<"">

const base: CSSObject = {
  display: "grid",
  maxWidth: 1380,
  margin: "auto",
  paddingTop: theme.spacing.large,
  paddingBottom: theme.spacing.large,
}

const variants: Variants<VariantStyle> = {
  footer: {
    gap: theme.spacing.large,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 1380,
    margin: "auto",
    paddingTop: 10,
    paddingBottom: 10,
  },
  sitemap: {
    gridArea: "sitemap",
    gap: theme.spacing.large,
    width: "100%",
    padding: 0,
    gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)"],
  },
  text: {
    gap: theme.spacing.large,
    maxWidth: 712,
  },
  card: {
    gridTemplateColumns: "repeat(auto-fit, minmax(326px, 327px))",
    justifyContent: "center",
    gap: theme.spacing.large,
  },
  block: {
    gap: theme.spacing.large,
    gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"],
    justifyContent: "center",
    maxWidth: [380, 1380],
  },
  topic: {
    gap: theme.spacing.large,
  },
  branding: {
    gap: theme.spacing.large,
    gridTemplateColumns: ["none", "none", "20%"],
    gridTemplateAreas: [
      `
      "branding"
      "sitemap"
    `,
      `
      "branding"
      "sitemap"
    `,
      `"branding sitemap sitemap sitemap sitemap"`,
    ],
  },
  advertisement: {
    gap: theme.spacing.large,
    gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)"],
    justifyContent: "center",
  },
}

export type GridTemplateStylesProps = {
  variant: VariantStyle
  options?: OptionStyle
}

export const gridTemplateStyles: StylesProps<GridTemplateStylesProps> = ({
  variant,
}) => {
  return mediaQuery([base, variants[variant]])
}
