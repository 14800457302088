import * as React from "react"

import slugify from "@sindresorhus/slugify"
import type {
  AdvertisementSectionOptions,
  AdvertisementSectionVariant,
} from "../contentful/contentful-advertisement-section"
import { advertisementStyles } from "./advertisement.styles"
import { useGtag } from "../../lib/use-gtag"
import { AdvertisementContent } from "./advertisement-content"
import { AdvertisementSingleMedia } from "../deprecated/advertisement-single-media"
import { ContentfulAdvertisement } from "../contentful/contentful-advertisement"

export type AdvertisementProps = {
  model: ContentfulAdvertisement
  options: AdvertisementSectionOptions
  variant: AdvertisementSectionVariant
}

export const Advertisement: React.FC<AdvertisementProps> = ({
  model,
  options,
  variant,
}) => {
  const [triggered, setTriggered] = React.useState(false)
  const { heading, singleMedia, poster } = model

  const handleOnMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (triggered) return null
    setTriggered(!triggered)

    useGtag("event", "hover", {
      event_id: event.currentTarget.dataset.analyticsId,
      event_category: "engagement",
      non_interaction: true,
    })
  }

  const analyticsId = `advertisement:${slugify(heading || "")}`
  const styles = advertisementStyles({ variant, options })

  return (
    <div
      css={styles}
      data-analytics-id={analyticsId}
      onMouseEnter={handleOnMouseEnter}
    >
      <AdvertisementContent model={model} options={options} variant={variant} />
      {options.media && singleMedia && (
        <AdvertisementSingleMedia
          variant={variant}
          model={singleMedia}
          poster={poster}
        />
      )}
    </div>
  )
}
